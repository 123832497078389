/* eslint-disable no-unused-vars */
// import React from "react";
import { useEffect } from "react";

import { Navigate } from "react-router-dom";

import { useMaterialUIController, setBypass } from "context";

function Logout(props) {
  const [controller, dispatch] = useMaterialUIController();

  // eslint-disable-next-line no-unused-vars
  const { bypass } = controller;

  useEffect(() => {
    setBypass(dispatch, false);
    localStorage.clear();
  }, []);
  return <Navigate to="/login" />;
}

export default Logout;
