import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import PersonIcon from "@mui/icons-material/Person";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import DomainVerificationOutlinedIcon from "@mui/icons-material/DomainVerificationOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
// import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";

function Base() {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      shadow="lg"
      p={0.5}
      width="fit-content"
      height="fit-content"
      bgColor="linear-gradient(to top, #2F2519, #4A3F35)"
      borderRadius="xl"
    >
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="#fff"
        p={0.5}
        m={1}
        width="fit-content"
        height="fit-content"
        borderRadius="xl"
      >
        <EngineeringOutlinedIcon />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="center">
        <MDBox
          borderRadius="xl"
          p={0.5}
          border="1px solid white"
          mb={0.5}
          display="flex"
          justifyContent="center"
        >
          <MDTypography
            variant="body1"
            fontSize="0.6rem"
            fontWeight="bold"
            sx={{
              color: "#ffffff",
            }}
          >
            EXPLORE SERVICES
          </MDTypography>
        </MDBox>
        {/* <MDBox mb={0}>
          <KeyboardDoubleArrowDownOutlinedIcon />
        </MDBox> */}
      </MDBox>
    </MDBox>
  );
}

export default Base;
