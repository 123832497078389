import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Loader } from "@mantine/core";
import logo from "assets/images/logo.png";
import PushInvite from "../../inboxCheck/index";

// Authentication layout components
// import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
// import bgImage from "assets/images/bg-reset-cover.jpeg";
import "../index.css";

function Reset() {
  const [emailInput, setEmail] = useState("");
  const [restErrorMsg, setErrormsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [successLink, setSuccess] = useState(false);
  const restPassword = async () => {
    setLoading(true);
    const url = "http://localhost:8000/auth/users/reset_password/";
    const postData = { email: emailInput };
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    };
    const response = await fetch(url, options);

    if (response.ok) {
      sessionStorage.setItem("userEmail", emailInput);
      setSuccess(true);
      setIsError(false);
      setLoading(false);
      setErrormsg("");
    } else {
      const data = await response.json();
      setSuccess(false);
      setLoading(false);
      setIsError(true);
      setErrormsg(data[0]);
    }
  };
  return (
    <>
      {!successLink && (
        <div className="rest-container">
          <Card sx={{ background: "#d7d7d7e0", marginTop: "3rem", width: "20rem" }}>
            <MDBox
              variant="gradient"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-7}
              py={2}
              mb={1}
              textAlign="center"
              sx={{ background: `radial-gradient(black, black)` }}
            >
              <MDBox
                sx={{ width: "53%", height: "60%", alignItems: "center", justifyContent: "center" }}
                component="img"
                variant="rect"
                src={logo}
              />
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Reset Password
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1} p={1}>
                You will receive an e-mail in maximum 60 seconds
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                {isError && <pre className="error-msg">{restErrorMsg}</pre>}
                <MDBox mb={4}>
                  <MDInput
                    error={isError}
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </MDBox>

                <MDBox mt={3} mb={1}>
                  <MDButton
                    disabled={emailInput.length === 0}
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={restPassword}
                  >
                    {isLoading ? <Loader color="white" size="sm" /> : "reset"}
                  </MDButton>
                </MDBox>
                <MDBox textAlign="center">
                  <MDTypography variant="button" color="info" fontWeight="medium" textGradient>
                    <a href="/"> Back </a>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </div>
      )}
      {successLink && <PushInvite />}
    </>
  );
}

export default Reset;
