import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import AddressInfoCard from "examples/Cards/InfoCards/AddressInfoCard";
// import ProfilesList from "examples/Lists/ProfilesList";
// import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
// import ProfileEditCard from "examples/Cards/InfoCards/ProfileEditCard";

// Overview page components
import Header from "layouts/profile/components/Header";
// import PlatformSettings from "layouts/profile/components/PlatformSettings";

import ProfileEditModal from "modals/ProfileEditModal";
import ProfileAddressEditModal from "modals/ProfileAddressEditModal";
import FullProfileEditModal from "modals/FullProfileEditModal";

import { fetchUserProfile } from "services/api.admin.service";

// Data
// import profilesListData from "layouts/profile/data/profilesListData";

// Images
// import homeDecor1 from "assets/images/home-decor-1.jpg";
// import homeDecor2 from "assets/images/home-decor-2.jpg";
// import homeDecor3 from "assets/images/home-decor-3.jpg";
// import homeDecor4 from "assets/images/home-decor-4.jpeg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

function Overview() {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [addressEdit, setAddressEdit] = useState(false);
  const [editModeMax, setEditModeMax] = useState(false);

  const [profileData, setProfileDAta] = useState({});

  // const [fullName, setFullName] = useState("");

  useEffect(() => {
    fetchUserProfile(id)
      .then((res) => {
        setProfileDAta(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   setFullName(`${profileData.data?.first_name  } ${  profileData.data?.last_name}`);
  // } , [profileData]);

  // console.log(profileData.data?.contact_mobile);
  const handleEditModeMax = () => {
    setEditModeMax((...prev) => !prev[0]);
  };

  const addressEditHandler = () => {
    setAddressEdit((...prev) => !prev[0]);
  };

  const editHandler = () => {
    setEditMode((...prev) => !prev[0]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header handleEditModeMax={handleEditModeMax} fullName={profileData.data?.first_name}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {editMode && <ProfileEditModal editMode={editMode} editHandler={editHandler} />}

              <ProfileInfoCard
                title="profile information"
                info={{
                  fullName: `${profileData.data?.first_name} ${profileData.data?.last_name}`,
                  mobile: profileData.data?.contact_mobile,
                  email: profileData.email,
                  location: profileData.data?.address?.city,
                }}
                social={[
                  {
                    link: "https://www.facebook.com",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              {addressEdit && <ProfileAddressEditModal addressEdit={addressEdit} />}
              <AddressInfoCard
                title="Address Information"
                info={{
                  address: `${profileData.data?.address?.address_line_1} ${profileData.data?.address?.address_line_2}`,
                  city: profileData.data?.address?.city,
                  state: profileData.data?.address?.state,
                  country: profileData.data?.address?.country,
                  zipCode: profileData.data?.address?.zip_code,
                }}
                shadow={false}
                addressEditHandler={addressEditHandler}
              />
            </Grid>
            {editModeMax && (
              <FullProfileEditModal
                email={profileData.email}
                firstName={profileData.data?.first_name}
                lastName={profileData.data?.last_name}
                mobile={profileData.data?.contact_mobile}
                location={profileData.data?.address?.city}
                address_line_1={profileData.data?.address?.address_line_1}
                address_line_2={profileData.data?.address?.address_line_2}
                city={profileData.data?.address?.city}
                state={profileData.data?.address?.state}
                country={profileData.data?.address?.country}
                zipCode={profileData.data?.address?.zip_code}
                editModeMax={editModeMax}
                handleEditModeMax={handleEditModeMax}
              />
            )}
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
