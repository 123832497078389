/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { Modal, Tabs } from "@mantine/core";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import Active from "./components/Active";
import Inactive from "./components/Inactive";
import Requested from "./components/Requested";

function Base({
  opened,
  setOpened,
  activeServices,
  inactiveServices,
  requestedServices,
  assetId,
  setInactiveServices,
  setReBreathe,
}) {
  const [active, setActive] = useState(true);
  const [inactive, setInactive] = useState(false);
  const [requested, setRequested] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handleActiveState = () => {
    setActive((prev) => !prev);
    setInactive(false);
    setRequested(false);
  };

  const handleInactiveState = () => {
    setActive(false);
    setInactive((prev) => !prev);
    setRequested(false);
  };

  const handleRequestedState = () => {
    setRequested((prev) => !prev);
    setActive(false);
    setInactive(false);
  };

  console.log(active, inactive);
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Services"
      style={{ display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1200 }}
      //   fullScreen
      size="xl"
    >
      {/* Modal content */}
      <MDBox display="block" width="100%" mb={1}>
        <Tabs defaultValue="active">
          <Tabs.List>
            <Tabs.Tab
              value="active"
              // onClick negate bool to previous state
              onClick={handleActiveState}
            >
              Active
            </Tabs.Tab>
            <Tabs.Tab value="inactive" onClick={handleInactiveState}>
              Inactive
            </Tabs.Tab>
            <Tabs.Tab value="requested" onClick={handleRequestedState}>
              <MDBox display="flex" flexDirection="row">
                <MDBox display="flex" flexDirection="row">
                  Requested
                </MDBox>
                {!loading && (
                  <MDBox
                    width={2.5}
                    height={2.5}
                    color="red"
                    bgColor="red"
                    display="flex"
                    alignItems="flex-start"
                    borderRadius="xl"
                    ml={0.25}
                    mt={-0.25}
                    style={{
                      transform: `scale(1.0)`,
                      transition: "transform 0.5s ease-in-out",
                    }}
                  />
                )}
              </MDBox>
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
      </MDBox>

      {active && <Active activeServices={activeServices} assetId={assetId} />}
      {inactive && (
        <Inactive
          inactiveServices={inactiveServices}
          assetId={assetId}
          setInactiveServices={setInactiveServices}
          setReBreathe={setReBreathe}
        />
      )}
      {requested && (
        <Requested
          requestedServices={requestedServices}
          assetId={assetId}
          setReBreathe={setReBreathe}
        />
      )}
    </Modal>
  );
}

export default Base;
