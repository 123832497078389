/* eslint-disable import/prefer-default-export */
export const tweekAgentRole = (email) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/tweek/agent/role`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchUserDetails = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/details`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const tapAgentLite = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/agent/tap/lite`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((dt) => dt);
  return result;
};

export const tapUserRole = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/tap/user/role`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((dt) => dt);
  return result;
};

export const fetchAgentProfile = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/create/profile`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};
// create user profile
export const createAgentProfile = (data) => {
  const user = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    gender: data.gender,
    personal_id: data.uniqueId,
    contact_mobile: data.phoneNumber,
    company_name: data.companyName,
    address_line_1: data.addressLine1,
    address_line_2: data.addressLine2,
    city: data.city,
    state: data.state,
    landmark: data.landmark,
    zip_code: data.zipCode,
    country: data.country,
    facebook_id: data.facebookId,
    linkdin_id: data.instaId,
    twitter_id: data.twitterId,
  };
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/create/profile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      return null;
    })
    // eslint-disable-next-line no-shadow
    .then((data) => data);
  return result;
};

// export const fetchAgentServicesToDo = () => {
//   const result = fetch("${process.env.REACT_APP_DOMAIN_NAME}/v1/")
// }

export const fetchAgentLatestProperties = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/assigned/properties/latest`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAllAssignedProperties = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/assigned/properties`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetDetails = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetUpdateDates = (assetServiceId) => {
  const result = fetch(
    `${process.env.REACT_APP_DOMAIN_NAME}/v1/get/asset/service/${assetServiceId}/updates`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetUpdateImages = (selectedId) => {
  const result = fetch(
    `${process.env.REACT_APP_DOMAIN_NAME}/v1/get/monitoring/update/${selectedId}/images`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

//  update unique asset by id
export const updateAsset = (id, images) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("auth_token")}`);
  // eslint-disable-next-line array-callback-return
  const formdata = new FormData();
  // eslint-disable-next-line no-plusplus

  images.forEach((image) => {
    formdata.append(`images`, image);
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  };

  const result = fetch(
    `${process.env.REACT_APP_DOMAIN_NAME}/v1/push/monitoring/update/${id}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((data) => data)
    .catch((error) => console.log("error", error));
  return result;
};

export const fetchServiceHistory = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/service/history`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};
