/* eslint-disable object-shorthand */
import React, { useEffect, useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

import { Badge, Loader } from "@mantine/core";
// import { Spin } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// import { activateNewUser } from "services/api.service";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import logo from "assets/images/logo.png";

function PushInvite() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <CoverLayout image={bgImage}>
      <Card sx={{ marginTop: 28, background: "#d7d7d7e0" }}>
        <MDBox
          variant="gradient"
          background="border"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-6}
          p={2}
          textAlign="center"
          sx={{ background: "black" }}
        >
          <MDBox
            sx={{ width: "53%", height: "60%", alignItems: "center", justifyContent: "center" }}
            component="img"
            variant="rect"
            src={logo}
          />
          <MDBox mt={4}>{loading && <Loader variant="bars" size="xs" />}</MDBox>
          {!loading && (
            <MDTypography variant="body1" fontWeight="medium" color="white" mt={1}>
              <Badge
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan" }}
                size="xs"
                radius="xs"
              >
                invitation Link sent to your email
              </Badge>
            </MDTypography>
          )}
        </MDBox>

        <MDBox mt={1} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            go ahead and{" "}
            <MDTypography
              to="/register"
              variant="button"
              color="error"
              fontWeight="medium"
              textGradient
            >
              Check your INBOX
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default PushInvite;
