import React from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import RequestedAssets from "./components/RequestedAssets";

function Base() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <RequestedAssets />
    </DashboardLayout>
  );
}

export default Base;
