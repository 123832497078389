import { useState, useEffect } from "react";
import { ScrollArea, Divider, Skeleton } from "@mantine/core";
// import { ListItem, ListItemText } from "@mui/material";
import { fetchUserNotifications } from "services/api.service";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import TimelineItem from "examples/Timeline/TimelineItem";
import { Card, Chip } from "@mui/material";
import NotificationItem from "./components/NotificationItem";

// Material Dashboard 2 React examples

// Data

function Projects() {
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    fetchUserNotifications().then((res) => {
      setNotifications(res);
      setLoading(false);
    });
  }, []);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox display="flex" alignItems="center">
          <Icon sx={{ color: "red" }} fontSize="inherit">
            notifications
          </Icon>
          <MDTypography pl={2} pr={1} variant="h6" gutterBottom sx={{ color: "red" }}>
            Notifications
          </MDTypography>
          <Chip color="primary" size="small" label="7" />
           <Divider />
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      {loading && (
        <MDBox height={200}>
          {[1, 2, 3].map((item) => (
            <div style={{ padding: 8 }}>
              <div
                key={item}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Skeleton height={8} circle />
                <Skeleton height={16} ml="xl" width="50%" />
              </div>
              <Skeleton height={8} mt={6} radius="xl" />
              <Skeleton height={8} mt={6} width="70%" radius="xl" />
            </div>
          ))}
        </MDBox>
      )}

      {!loading && notifications.length > 0 && (
        <ScrollArea style={{ height: 200 }} scrollbarSize={2}>
          <MDBox px={3}>
            {/* List Items for notification section *|* 3 */}
            {notifications.map((data) => (
              <div key={data}>
                <NotificationItem
                  icon="notifications"
                  title={data.title}
                  description={data.description}
                />
              </div>
            ))}
          </MDBox>
        </ScrollArea>
      )}
      {!loading && notifications.length === 0 && (
        <MDBox height={200} display="flex" alignItems="center" justifyContent="center">
          {/* List Items for notification section *|* 3 */}
          <MDTypography variant="subtitle2">You have no notifications at the moment</MDTypography>
        </MDBox>
      )}
    </Card>
  );
}
export default Projects;
