import React, { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";

import PropertyTable from "./PropertyTable";
import AssetUpdate from "./PropertyTable/components/AssetUpdate";

function Base() {
  const [viewUpdate, setViewUpdate] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setViewUpdate(false);
  }, []);
  console.log("data", data);
  return (
    <DashboardLayout>
      {!viewUpdate && <DashboardNavbar />}
      <MDBox width="100%">
        {!viewUpdate && (
          <MDBox display="flex" flexDirection="column" mt={2}>
            <MDBox ml={1} mr={1} mb={1} p={1}>
              <MDTypography style={{ fontSize: "14px", fontWeight: "400", color: "#af97b1" }}>
                Assigned Properties
              </MDTypography>
            </MDBox>
            <MDBox>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <PropertyTable setViewUpdate={setViewUpdate} setData={setData} data={data} />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      {viewUpdate && <AssetUpdate setViewUpdate={setViewUpdate} data={data} />}
    </DashboardLayout>
  );
}

export default Base;
