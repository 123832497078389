import React from "react";

import { useParams } from "react-router-dom";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AssetInfoCard from "./components/AssetInfoCard";
import AssetUpdateComponent from "./components/AssetUpdateComponent";

function Assets() {
  const { cid, id } = useParams();

  //   const [updateDates, setUpdateDates] = useState([]);
  //   const [selectedId, setSelectedId] = useState("");

  //   useEffect(() => {
  //     fetchAssetUdates(id).then((res) => {
  //       setUpdateDates(res);
  //       setSelectedId(res[0].id);
  //     });
  //   }, []);

  //   const handleClick = (id) => {
  //     setSelectedId(id);
  //   };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1} display="flex" flexDirection="column">
        <MDBox>
          <AssetInfoCard id={id} cid={cid} />
        </MDBox>
        <MDBox py={2} sx={{ height: "100%" }}>
          <AssetUpdateComponent />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Assets;
