/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

// import { Typography } from "@mui/material";
import { Box } from "@mantine/core";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function GeneralInfo({ assetDetailsData }) {
  const [north, setNorth] = React.useState("-");
  const [east, setEast] = React.useState("-");
  const [south, setSouth] = React.useState("-");
  const [west, setWest] = React.useState("-");

  useEffect(() => {
    if (assetDetailsData.dimensions_north) {
      setNorth(assetDetailsData.dimensions_north);
    }
    if (assetDetailsData.dimensions_east) {
      setEast(assetDetailsData.dimensions_east);
    }
    if (assetDetailsData.dimensions_south) {
      setSouth(assetDetailsData.dimensions_south);
    }
    if (assetDetailsData.dimensions_west) {
      setWest(assetDetailsData.dimensions_west);
    }
  }, [assetDetailsData]);

  let date = new Date(
    assetDetailsData.year_of_registration ? assetDetailsData.year_of_registration : ""
  ).toLocaleDateString();

  if (date === "Invalid Date") {
    date = "NA";
  }

  const dimensions = `${north}N x ${east}E x ${west}W x ${south}S`;

  const fieldsJson = [
    {
      field: "Size in Yards",
      value: assetDetailsData.size_in_yards,
    },
    {
      field: "Facing",
      value: assetDetailsData.site_facing,
    },
    {
      field: "Survey No.",
      value: assetDetailsData.survey_number,
    },
    {
      field: "Registration No.",
      value: assetDetailsData.registration_number,
    },
    {
      field: "Sub-Registrar Office",
      value: assetDetailsData.sub_registrar_office,
    },
    {
      field: "Year of Registration",
      value: date,
    },
    {
      field: "Geo-Location",
      value: "Latd -- Long",
    },
    {
      field: "Address",
      value: assetDetailsData.address?.location_name,
    },
    {
      field: "Dimensions",
      value: dimensions,
    },
  ];

  return (
    <MDBox
      shadow="lg"
      variant="gradient"
      display="flex"
      coloredShadow="info"
      borderRadius="lg"
      boxShadow="xl"
      p={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <MDBox sx={{ width: "100%" }}>
        <MDTypography
          variant="body"
          style={{
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "left",
            padding: 5,
            marginLeft: 4,
          }}
        >
          General Information
        </MDTypography>
      </MDBox>
      <MDBox
        mt={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          paddingLeft: "15px",
          paddingRight: "15px",
          justifyContent: "space-between",
        }}
        xs={8}
        md={4}
        lg={3}
      >
        {fieldsJson.map((data, index) => (
          <Box
            style={{ textAlign: "left" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "33%",
              marginBottom: 10,
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <MDTypography
              variant="body2"
              color="text.secondary"
              component="div"
              style={{ alignText: "left", fontSize: "12px", fontWeight: "400" }}
            >
              {data.field}
            </MDTypography>
            <MDTypography
              variant="body1"
              color="text.primary"
              component="div"
              style={{ fontSize: "14px", fontWeight: "700" }}
            >
              {data.value}
            </MDTypography>
          </Box>
        ))}
      </MDBox>
    </MDBox>
  );
}

export default GeneralInfo;
