/* eslint-disable object-shorthand */
import React, { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import CircularProgress from "@mui/material/CircularProgress";
// import Checkbox from "@mui/material/Checkbox";

import { Badge, Loader } from "@mantine/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// import CoverLayout from "layouts/authentication/components/CoverLayout";

import { createUser } from "services/api.service";

// Images
// import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import logo from "assets/images/logo.png";
import "./index.css";

function Cover() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [load, setLoad] = useState(false);
  const [signupErrors, setErrors] = useState({
    emailError: "",
    passwordError: "",
    conformPassword: "",
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRePasswordChange = (e) => {
    setRePassword(e.target.value);
    if (password.includes(e.target.value)) {
      setErrors({ ...signupErrors, conformPassword: "" });
    } else {
      setErrors({ ...signupErrors, conformPassword: "password is not matching" });
    }
  };

  const handleSubmit = () => {
    setLoad(true);
    const data = {
      firsh_name: name,
      email: email,
      password: password,
      re_password: rePassword,
    };
    createUser(data).then((res) => {
      console.log(res);
      if (res.response.ok) {
        console.log(res);
        setLoad(false);
        navigate("/push/invite");
      } else {
        setLoad(false);
        if ("email" in res.result) {
          setErrors({ emailError: res.result.email[0], passwordError: "", conformPassword: "" });
        } else {
          setErrors({ passwordError: res.result.password[0], emailError: "", conformPassword: "" });
        }
      }
    });
  };

  return (
    <div className="sigup-bg">
      <Card sx={{ marginTop: 10, background: "#d7d7d7e0", width: "20rem" }}>
        <MDBox
          variant="gradient"
          // bgColor="info"
          background="border"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-10}
          p={2}
          textAlign="center"
          sx={{ background: "black" }}
        >
          <MDBox
            sx={{ width: "53%", height: "60%", alignItems: "center", justifyContent: "center" }}
            component="img"
            variant="rect"
            src={logo}
          />
          <MDTypography variant="body1" fontWeight="medium" color="white" mt={1}>
            <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
              Join us today
            </Badge>
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                variant="standard"
                value={name}
                onChange={handleNameChange}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                error={signupErrors.emailError && true}
                type="email"
                label="Email"
                variant="standard"
                value={email}
                onChange={handleEmailChange}
                fullWidth
              />
              <p style={{ color: "red", fontSize: "12px" }}>{signupErrors.emailError}</p>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                error={signupErrors.passwordError && true}
                type="password"
                label="Password"
                variant="standard"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
              />
              <p style={{ color: "red", fontSize: "12px" }}>{signupErrors.passwordError}</p>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                error={signupErrors.conformPassword && true}
                type="password"
                label="Confirm Password"
                variant="standard"
                value={rePassword}
                onChange={handleRePasswordChange}
                fullWidth
              />
              <p style={{ color: "red", fontSize: "12px", marginTop: "0px" }}>
                {signupErrors.conformPassword}
              </p>
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            {!load ? (
              <MDBox mt={1} mb={1}>
                <MDButton variant="gradient" onClick={handleSubmit} color="info" fullWidth>
                  sign up
                </MDButton>
              </MDBox>
            ) : (
              <MDBox mt={1} mb={1} display="flex" alignItems="center" justifyContent="center">
                <MDButton variant="gradient" onClick={handleSubmit} color="info" fullWidth>
                  {/* <CircularProgress
                    size={20}
                    sx={{
                      color: "#fff",
                      position: "absolute",
                      top: 7,
                      left: 107,
                      zIndex: 1,
                    }}
                  /> */}
                  <Loader color="indigo" size="sm" />
                </MDButton>
              </MDBox>
            )}
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </div>
  );
}

export default Cover;
