/* eslint-disable camelcase */
// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import StyledRibbonCard from "components/Card";

function DefaultProjectCard({ image, label, title, description, action, authors }) {
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <StyledRibbonCard
      ribbonText={label}
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: "1rem",
      }}
    >
      <MDBox position="relative" shadow="xl" borderRadius="xl" mt={2} p={1} display="block">
        <MDBox>
          <CardMedia
            src={image}
            // eslint-disable-next-line camelcase
            height="100px"
            width="100px"
            component="img"
            title={title}
            sx={{
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          {/* {loading && (
            <MDBox
              sx={{
                width: "100px",
                height: "100px",
                backgroundColor: "grey.500",
                borderRadius: "xl",
              }}
            />
          )} */}
        </MDBox>

        <MDBox mt={1} mx={0.5}>
          <MDBox mb={1}>
            {action.type === "internal" ? (
              <MDTypography
                component={Link}
                to={action.route}
                variant="h5"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            ) : (
              <MDTypography
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mb={3} lineHeight={0}>
            <MDTypography variant="button" fontWeight="light" color="text">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            {action.type === "internal" ? (
              <MDButton
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </MDButton>
            ) : (
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </MDButton>
            )}
            <MDBox display="flex">{renderAuthors}</MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </StyledRibbonCard>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultProjectCard;
