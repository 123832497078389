import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import AddressInfoCard from "examples/Cards/InfoCards/AddressInfoCard";
// import ProfilesList from "examples/Lists/ProfilesList";
// import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
// import ProfileEditCard from "examples/Cards/InfoCards/ProfileEditCard";

// Overview page components
import Header from "layouts/profile/components/Header";
// import PlatformSettings from "layouts/profile/components/PlatformSettings";

import ProfileEditModal from "modals/ProfileEditModal";
import ProfileAddressEditModal from "modals/ProfileAddressEditModal";
import FullProfileEditModal from "modals/FullProfileEditModal";

import { fetchUserDetails, tapUserLite, updateUserDetailsSelf } from "services/api.service";

import CreateProfile from "./components/CreateProfile";

// Data
// import profilesListData from "layouts/profile/data/profilesListData";

// Images
// import homeDecor1 from "assets/images/home-decor-1.jpg";
// import homeDecor2 from "assets/images/home-decor-2.jpg";
// import homeDecor3 from "assets/images/home-decor-3.jpg";
// import homeDecor4 from "assets/images/home-decor-4.jpeg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

function Overview() {
  const [editMode, setEditMode] = useState(false);
  const [addressEdit, setAddressEdit] = useState(false);
  const [editModeMax, setEditModeMax] = useState(false);

  const [profileData, setProfileData] = useState({});

  const [loading, setLoading] = useState(false);
  const [createProfile, setCreateProfile] = useState(false);

  // const [fullName, setFullName] = useState("");

  // useEffect(() => {
  //   setLoading(true);
  //   tapUserLite().then((res) => {
  //     setCreateProfile(res.isNewUser);
  //     setLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    fetchUserDetails()
      .then((res) => {
        console.log(res, "fetching profile data");
        setProfileData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   setFullName(`${profileData.data?.first_name  } ${  profileData.data?.last_name}`);
  // } , [profileData]);

  // console.log(profileData.data?.contact_mobile);
  const handleEditModeMax = () => {
    setEditModeMax((...prev) => !prev[0]);
  };

  const addressEditHandler = () => {
    setAddressEdit((...prev) => !prev[0]);
  };

  const editHandler = () => {
    setEditMode((...prev) => !prev[0]);
  };

  const handleProfileStatusChange = () => {
    setTimeout(() => {
      setLoading(true);
      tapUserLite().then((res) => {
        setCreateProfile(res.isNewUser);
        setLoading(false);
      });
      fetchUserDetails().then((res) => {
        setProfileData(res);
      });
    }, 2000);
  };

  const handleSave = (user) => {
    updateUserDetailsSelf(user).then((res)=> {
      if(res){
        fetchUserDetails().then((data) => {
          setProfileData(data);
          setEditModeMax(false);
        });
      }
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {!loading && createProfile && (
        <CreateProfile handleProfileStatusChange={handleProfileStatusChange} />
      )}
      {!loading && !createProfile && (
        <Header handleEditModeMax={handleEditModeMax} editMode={editModeMax} fullName={profileData?.first_name}>
          <MDBox mt={2} mb={3}>
            <Grid container spacing={1} className="container-grid">
              {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
              {!editModeMax && (
                <>
                  <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                    <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                    {editMode && <ProfileEditModal editMode={editMode} editHandler={editHandler} />}

                    <ProfileInfoCard
                      title="profile information"
                      info={{
                        fullName: `${profileData?.first_name} ${profileData?.last_name}`,
                        mobile: profileData?.contact_mobile,
                        email: profileData.email,
                        location: profileData?.address?.city,
                      }}
                      social={[
                        {
                          link: "https://www.facebook.com",
                          icon: <FacebookIcon />,
                          color: "facebook",
                        },
                        {
                          link: "https://twitter.com",
                          icon: <TwitterIcon />,
                          color: "twitter",
                        },
                        {
                          link: "https://www.instagram.com",
                          icon: <InstagramIcon />,
                          color: "instagram",
                        },
                      ]}
                      shadow={false}
                    />
                    <Divider orientation="vertical" sx={{ mx: 0 }} />
                  </Grid>
                  <Grid item xs={12} xl={4}>
                    {addressEdit && <ProfileAddressEditModal addressEdit={addressEdit} />}
                    <AddressInfoCard
                      title="Address Information"
                      info={{
                        address: `${profileData?.address}`,
                        city: profileData?.city,
                        state: profileData?.state,
                        country: profileData?.country,
                        zipCode: profileData?.pin_code,
                      }}
                      shadow={false}
                      addressEditHandler={addressEditHandler}
                    />
                  </Grid>
                </>
              )}
              {editModeMax && (
                <FullProfileEditModal
                  profileData={profileData}
                  editModeMax={editModeMax}
                  handleSave={handleSave}
                />
              )}
            </Grid>
          </MDBox>
        </Header>
      )}
    </DashboardLayout>
  );
}

export default Overview;
