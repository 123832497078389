/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "context";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

ReactDOM.render(
  <BrowserRouter>
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <NotificationsProvider position="top-right" zIndex={2077}>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </NotificationsProvider>
    </MantineProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
