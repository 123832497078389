/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { ScrollArea, Loader } from "@mantine/core";

import { Grid, ListItem, Box } from "@mui/material";

import { fetchAssetUpdateDates, updateAsset } from "services/api.agent.service";

import { DropzoneDialog } from "material-ui-dropzone";

import ImageViewComponent from "./ImageViewComponent";

function AssetUpdateComponent({ data }) {
  const assetServiceId = data.asset_service?.id;

  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = useState(false);

  const [updateDates, setUpdateDates] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log("selectedId, ", selectedId);

  useEffect(() => {
    setLoading(true);
    fetchAssetUpdateDates(assetServiceId).then((res) => {
      console.log(res.message);
      if (res.message === "No updates found") {
        setLoading(false);
        setUpdateDates([]);
      } else {
        setUpdateDates(res);
      }
      if (res.length > 0) {
        setSelectedId(res[0].id);
      }
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   if (updateDates.length > 0) {
  //     setSelectedId(updateDates[0].id);
  //   }
  // }, [updateDates]);

  const handleClick = (itemId) => {
    setSelectedId(itemId);
  };

  const handleonSave = (images) => {
    updateAsset(assetServiceId, images).then((res) => {
      // insert res to updated dates at 0 position
      const item = JSON.parse(res);
      setUpdateDates([item, ...updateDates]);
      // const arr = [...updateDates, JSON.parse(res)];
      // setUpdateDates(arr);
      setSelectedId(item.id);
    });
    setOpen(false);
  };
  console.log("newUpdates", updateDates);

  return (
    <Grid
      sx={{
        display: "flex",
        width: "100%",
        flexGrow: 1,
        justifyContent: "space-around",
      }}
    >
      {loading ? (
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <Loader color="dark" variant="bars" />
        </MDBox>
      ) : (
        updateDates.length === 0 && (
          <MDBox
            sx={{
              width: "20%",
              height: "100%",
              borderRight: "1px solid #e0e0e0",
            }}
          >
            <MDTypography variant="h6" sx={{ textAlign: "center" }}>
              No Updates yet. Checkout the Available services for your asset in details.
            </MDTypography>
          </MDBox>
        )
      )}

      {!loading && (
        <Grid item span={10}>
          {/* Services section */}
          <MDBox py={2}>
            <MDButton
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
              style={{ width: "97%" }}
            >
              UPLOAD
            </MDButton>
          </MDBox>
          <DropzoneDialog
            acceptedFiles={["image/*"]}
            cancelButtonText="cancel"
            submitButtonText="submit"
            maxFileSize={5000000}
            open={open}
            onClose={() => setOpen(false)}
            onSave={(images) => {
              handleonSave(images);
            }}
            showPreviews
            showFileNamesInPreview
          />
          <MDBox
            style={{
              backgroundColor: "#f5f5f5",
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 300,
              height: 270,
              marginRight: 1,
              boxShadow: "0px 0px 10px 0px rgba(0,0,1,0.2)",
            }}
            xs={12}
            md={4}
            lg={4}
          >
            {/* <AssetServices /> */}
            <MDBox sx={{ display: "flex", widht: "100%" }}>
              <MDTypography variant="h6" fontWeight="medium" pt={3} px={2}>
                Updates
              </MDTypography>
            </MDBox>
            <ScrollArea style={{ height: 265 }} scrollbarSize={2}>
              <MDBox
                pt={1}
                px={2}
                sx={{
                  width: "100%",
                }}
                style={{
                  "&:hover": {
                    boxShadow: "0px 0px 10px rgba(0, 0, 0.5, 1)",
                  },
                }}
              >
                {updateDates.map(
                  (item) =>
                    item.images_exists === true && (
                      <div key={item.id}>
                        <ListItem button>
                          <MDBox
                            sx={{
                              width: "100%",
                              p: 1,
                              pl: 1.5,
                              pr: 1.5,
                              borderBottom: "1px solid #ccc",
                              "&:hover": {
                                boxShadow: "0px 0px 10px rgba(0, 0, 0.5, 1)",
                                cursor: "pointer",
                              },
                              "&:onClick": {
                                color: "red",
                              },
                              "&:active": {
                                color: "red",
                              },
                              "&:focus": {
                                boxShadow: "0px 0px 10px rgba(0, 0, 0.5, 1)",
                              },
                              "&:selected": {
                                backgroundColor: "#ccc",
                                boxShadow: "0px 0px 10px rgba(0, 0, 0.5, 1)",
                              },
                              "&:de-selected": {
                                backgroundColor: "transparent",
                              },
                            }}
                            onClick={() => handleClick(item.id)}
                          >
                            {item.id === selectedId ? (
                              <MDTypography
                                component="div"
                                sx={{
                                  fontSize: "14px",
                                  color: "#cc0000",
                                }}
                              >
                                {item.last_update.split("T")[0]}
                              </MDTypography>
                            ) : (
                              <MDTypography
                                component="div"
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                {item.last_update.split("T")[0]}
                              </MDTypography>
                            )}
                          </MDBox>
                        </ListItem>
                      </div>
                    )
                )}
              </MDBox>
            </ScrollArea>
          </MDBox>
        </Grid>
      )}
      <Grid item span={20}>
        <Box
          style={{
            backgroundColor: "#dedede",
            // background: "linear-gradient(to bottom, #efd5d5, #f5f5f5)",
          }}
          sx={{
            display: "block",
            height: 340,
            marginLeft: 1,
            marginTop: 2,
            boxShadow: "0px 0px 10px 0px rgba(0,0,1,0.5)",
          }}
          xs={12}
          md={8}
          lg={8}
        >
          {/* <MDTypography variant="h5">ALT Update Images here</MDTypography> */}
          <ImageViewComponent selectedId={selectedId} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default AssetUpdateComponent;
