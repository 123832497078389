import { CloudUploadOutlined } from "@ant-design/icons";
import { Typography } from "@mui/material";
import { Upload } from "antd";
// import { Typography } from "@mui/material";

// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    // if (status === "done") {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === "error") {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

function UploadDragTypeDocuments() {
  return (
    <div style={{ alignItems: "center" }}>
      <Dragger {...props}>
        <MDBox p={2} display="flex" alignItems="center" flexDirection="column">
          <CloudUploadOutlined />
          <Typography variant="body2">Drop photos to attach, or click to browse</Typography>
        </MDBox>
      </Dragger>
    </div>
  );
}

export default UploadDragTypeDocuments;
