import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { CardContent, ListItem } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";

import { Space, Divider } from "@mantine/core";

import { getAssetServices, approveService } from "services/api.admin.service";

function AssetServices() {
  const { id } = useParams();
  const [inactiveServices, setInactiveServices] = useState([]);
  const [activeServices, setActiveServices] = useState([]);

  React.useEffect(() => {
    getAssetServices(id).then((res) => {
      setInactiveServices(res.inactive_services);
      setActiveServices(res.active_services);
    });
  }, []);

  const handleServiceClick = (sid) => {
    approveService(id, sid).then((res) => {
      console.log(res);
    });
  };

  return (
    <MDBox
      mt={1}
      shadow="lg"
      color="white"
      variant="gradient"
      borderRadius="lg"
      sx={{
        // height: 500,
        // width: "100%",
        alignItems: "center",
        // boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
      }}
    >
      <CardContent>
        <MDTypography variant="body2" component="div">
          Active Services&nbsp;({activeServices.length})
        </MDTypography>
        {activeServices.map((item) => (
          <MDBox
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "100%",
              marginTop: "20px",
              flexGrow: 1,
              backgroundColor: "#edf5f9",
              borderRadius: 4,
              alignItems: "center",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.6)",
            }}
          >
            <ListItem
              sx={{
                "&:hover": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 1, 0.5)",
                  cursor: "pointer",
                },
              }}
            >
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="body1"
                  bold
                  p={1}
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  {item.service?.serviceName}
                </MDTypography>
              </MDBox>
            </ListItem>
          </MDBox>
        ))}
        <Space h="md" />
        <Divider variant="dashed" />
        <Space h="md" />
        <MDTypography variant="body2" component="div">
          Available Services
        </MDTypography>
        <Space h="md" />
        {inactiveServices.map((item) => (
          <MDBox
            display="flex"
            flexDirection="row"
            shadow="lg"
            borderRadius="lg"
            alignItems="cneter"
            color="white"
            variant="gradient"
            justifyContent="flex-sart"
            style={{
              width: "100%",
              marginTop: "20px",
              flexGrow: 1,
              backgroundColor: "#edf5f9",
            }}
          >
            <ListItem
              value={item.id}
              onClick={() => handleServiceClick(item.id)}
              sx={{
                "&:hover": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 1, 0.5)",
                  cursor: "pointer",
                },
              }}
            >
              <MDTypography variant="body2" p={1}>
                {item.serviceName}
              </MDTypography>
              <MDBox display="flex" justifyContent="flex-end">
                <AddBusinessIcon
                  sx={{
                    color: "#cc0000",
                    fontSize: "25px",
                    marginRight: "10px",
                  }}
                  variant="filled"
                />
              </MDBox>
            </ListItem>
          </MDBox>
        ))}
        {/* <CouroselPlay data={inactiveServices} /> */}
      </CardContent>
    </MDBox>
  );
}

export default AssetServices;
