import { useState } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Loader } from "@mantine/core";
import logo from "assets/images/logo.png";

function ResetConformPassword() {
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [restErrorMsg, setErrormsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const userEmail = sessionStorage.getItem("userEmail");
  const restPassword = async () => {
    setLoading(true);
    const url = "http://localhost:8000/auth/users/reset_password_confirm/";
    const postData = { uid, token, new_password: newPassword };
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    };
    const response = await fetch(url, options);
    console.log(response);

    if (response.ok) {
      setIsError(false);
      setLoading(false);
      setErrormsg("");
      setSuccess(true);
    } else {
      console.log(await response.json());
      setSuccess(false);
      setLoading(false);
      setIsError(true);
      setErrormsg("request expired, plz go back and try again");
    }
  };

  const typeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    const isSame = newPassword === e.target.value;
    if (isSame) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  };
  console.log(uid, token);
  return (
    <div className="rest-container">
      <Card sx={{ background: "#d7d7d7e0", marginTop: "3rem", width: "20rem" }}>
        {!isSuccess && (
          <>
            <MDBox
              variant="gradient"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-7}
              py={2}
              mb={1}
              textAlign="center"
              sx={{ background: `radial-gradient(black, black)` }}
            >
              <MDBox
                sx={{ width: "53%", height: "60%", alignItems: "center", justifyContent: "center" }}
                component="img"
                variant="rect"
                src={logo}
              />
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                New Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                {isError && (
                  <pre className="error-msg" style={{ fontSize: "0.7rem" }}>
                    {restErrorMsg}
                  </pre>
                )}
                <p style={{ color: "#4b4bd3", textAlign: "center" }}>
                  <pre>{userEmail}</pre>
                </p>
                <MDBox mb={4}>
                  <MDInput
                    type="password"
                    label="New password"
                    variant="standard"
                    fullWidth
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {newPassword.length > 1 && newPassword.length < 8 && (
                    <p style={{ color: "red", fontSize: "12px", marginTop: "0px" }}>
                      password must contain at least 8 character
                    </p>
                  )}
                </MDBox>
                <MDBox mb={4}>
                  <MDInput
                    error={isConfirm}
                    type="password"
                    label="Confirm password"
                    variant="standard"
                    fullWidth
                    onChange={typeConfirmPassword}
                  />
                  {isConfirm && (
                    <p style={{ color: "red", fontSize: "12px", marginTop: "0px" }}>
                      password not matched
                    </p>
                  )}
                </MDBox>

                <MDBox mt={3} mb={1}>
                  <MDButton
                    disabled={confirmPassword.length === 0 || isConfirm}
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={restPassword}
                  >
                    {isLoading ? <Loader color="white" size="sm" /> : "change password"}
                  </MDButton>
                </MDBox>
                <MDBox textAlign="center">
                  <MDTypography variant="button" color="info" fontWeight="medium" textGradient>
                    <a href="/forgot-password"> Back </a>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </>
        )}
        {isSuccess && (
          <section
            style={{ padding: "2rem", display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <i
              className="fa-regular fa-circle-check fa-shake"
              style={{ color: "#418609", fontSize: "3rem" }}
            />
            <nav style={{ paddingLeft: "1rem" }}>
              <p>successfully password changed</p>
              <p style={{ fontSize: "0.9rem" }}>
                back to <a href="/login">LOGIN</a>
              </p>
            </nav>
          </section>
        )}
      </Card>
    </div>
  );
}

export default ResetConformPassword;
