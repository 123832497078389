/* eslint-disable object-shorthand */
import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

import { Badge } from "@mantine/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// import { activateNewUser } from "services/api.service";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import logo from "assets/images/logo.png";

function Cover() {
  const { uid, token } = useParams();
  const [verified, setVerified] = useState(false);
  useEffect(() => {
    fetch(`http://localhost:8000/auth/users/activation/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: uid,
        token: token,
      }),
    }).then((response) => {
      if (response.status === 204) {
        setVerified(true);
      } else {
        setVerified(false);
      }
    });
  }, []);

  console.log(verified);

  return (
    <CoverLayout image={bgImage}>
      <Card sx={{ marginTop: 28, background: "#d7d7d7e0" }}>
        <MDBox
          variant="gradient"
          // bgColor="info"
          background="border"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-6}
          p={2}
          textAlign="center"
          sx={{ background: "black" }}
        >
          <MDBox
            sx={{ width: "53%", height: "60%", alignItems: "center", justifyContent: "center" }}
            component="img"
            variant="rect"
            src={logo}
          />
          {verified && (
            <MDTypography variant="body1" fontWeight="medium" color="white" mt={1}>
              <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
                Email Verified
              </Badge>
            </MDTypography>
          )}
          {!verified && (
            <MDTypography variant="body1" fontWeight="medium" color="white" mt={1}>
              <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
                Verification Failed
              </Badge>
            </MDTypography>
          )}
        </MDBox>

        {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}

        <MDBox mt={1} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Continue to{" "}
            {verified && (
              <MDTypography
                component={Link}
                to="/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            )}
            {!verified && (
              <MDTypography
                component={Link}
                to="/register"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign Up
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
