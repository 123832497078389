import React from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PersonIcon from "@mui/icons-material/Person";

function Base() {
  const navigate = useNavigate();

  const hadndleProfileClick = () => {
    navigate("/profile");
  };

  return (
    <MDBox
      display="flex"
      flexDirection="row"
      width="max-content"
      p={1}
      shadow="lg"
      bgColor="linear-gradient(to top right, #0c5266, #11738f)"
      borderRadius="xl"
    >
      <MDBox
        display="inline-flex"
        bgColor="#fff"
        p={1}
        m={1}
        height="fit-content"
        borderRadius="xl"
      >
        <PersonIcon fontSize="medium" />
      </MDBox>
      <MDBox display="flex" flexDirection="column">
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDBox ml={1} mt={0.5}>
            <MDTypography
              variant="body1"
              fontSize="1.0rem"
              sx={{
                color: "#ffffff",
              }}
            >
              Profile completion
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          borderRadius="xl"
          p={0.5}
          border="1px solid white"
          mt={1}
          mb={1}
          display="flex"
          justifyContent="center"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={hadndleProfileClick}
        >
          <MDTypography
            variant="body1"
            fontSize="0.6rem"
            fontWeight="bold"
            sx={{
              color: "#ffffff",
            }}
          >
            COMPLETE YOUR PROFILE
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Base;
