/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { Modal, Divider, LoadingOverlay } from "@mantine/core";

import MDBox from "components/MDBox";

import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";

import {
  fetchAssetDetails,
  associateAssetAgentServices,
  fetchAssetRequests,
} from "services/api.admin.service";

import MDInput from "components/MDInput";
import { Button } from "@mui/material";
import MultiSelect from "./components/ServiceMultiSelectComp";
import AssignAgent from "./components/AgentAssignment";

// eslint-disable-next-line react/prop-types
// FIXME: setOpened prop types
function Base({ setOpened, opened, handleRowClick, data, setElements }) {
  const [value, setValue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [assetDetails, setAssetDetails] = useState();
  const [agentSelected, setAgentSelected] = useState();

  // Modal Overlay blur
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchAssetDetails(data.asset_id).then((res) => {
      setAssetDetails(res);
    });
  }, [data.asset_id]);

  // const handleAssignClick = () => {
  //   // setVisible(true);
  //   associateAssetAgentServices(assetDetails.id, value, agentSelected).then((res) => {
  //     console.log("res: ", res);
  //   });
  // };

  useEffect(() => {
    if (visible) {
      associateAssetAgentServices(data.asset_id, value, agentSelected).then((res) => {
        if (res) {
          setVisible(false);
          setOpened(false);
        }
      });
    }
  }, [visible]);

  useEffect(() => {
    if (!opened) {
      fetchAssetRequests().then((res) => {
        if (res) {
          setElements(res);
        }
      });
    }
  }, [opened]);

  return (
    <div style={{ position: "relative" }}>
      <Modal
        opened={opened}
        onClose={handleRowClick}
        overlayOpacity={0.55}
        overlayBlur={5}
        size="lg"
        radius="lg"
        zIndex={2000}
        lockScroll
      >
        {/* <div style={{ position: "relative" }}> */}
        <LoadingOverlay
          loaderProps={{ size: "sm", color: "pink", variant: "circle" }}
          visible={visible}
          overlayColor="#c5c5c5"
          overlayBlur={1}
          style={{ borderRadius: "16px" }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <MDTypography style={{ fontSize: "12px", color: "#2e2e2e" }}>
              Asset Details
            </MDTypography>
            <MDBox display="flex" width="100%" alignItems="center">
              <MDBox width="100%">
                <MDInput value={data.asset_type} label="Asset Type" disabled>
                  {data.asset_type}
                </MDInput>
              </MDBox>
              <MDBox p={2} width="100%">
                <MDInput
                  label="Name"
                  textColor="secondary"
                  value={data.asset_name}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  xs={6}
                  name="name"
                  disabled
                />
              </MDBox>
              <MDBox p={1} width="100%">
                <MDInput
                  label="Asset Status"
                  variant="outlined"
                  value={data.asset_status ? "Active" : "Inactive"}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled
                  name="status"
                />
              </MDBox>
            </MDBox>
            <Divider />
            <MDTypography style={{ fontSize: "12px", color: "#2e2e2e" }}>
              Assign Services to Agent
            </MDTypography>
            <MDBox display="flex" alignItems="center">
              <MDBox pr={2} width="50%">
                <MDBox mb={1}>
                  <MultiSelect setValue={setValue} data={data} />
                </MDBox>
              </MDBox>
              <MDBox width="50%">
                <MDBox mb={1}>
                  <AssignAgent setAgentSelected={setAgentSelected} />
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="center" my={2}>
              <Button
                variant="contained"
                size="medium"
                color="success"
                onClick={() => setVisible(true)}
              >
                <MDTypography style={{ fontSize: "12px" }}>Assign</MDTypography>
              </Button>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default Base;
