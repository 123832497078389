/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Select } from "@mantine/core";
import { fetchAgentsInfo } from "services/api.admin.service";

const AssignAgent = ({ setAgentSelected }) => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    fetchAgentsInfo().then((res) => setAgents(res));
  }, []);

  const handleOnChange = (value) => setAgentSelected(value);

  const agentOptions = agents.map(({ first_name, user }) => ({
    value: first_name,
    label: first_name,
    email: user?.email,
  }));

  return (
    <Select
      label="Choose employee"
      placeholder="Pick one"
      data={agentOptions}
      searchable
      maxDropdownHeight={400}
      nothingFound="Nobody here"
      onChange={handleOnChange}
      filter={(value, item) => item.label.toLowerCase().includes(value.toLowerCase().trim())}
    />
  );
};

export default AssignAgent;
