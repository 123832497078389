/* eslint-disable */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import SidenavCollapse from "./SidenavCollapse";
import "./index.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogPopup(props) {
  const navigate = useNavigate();
  const { details } = props;
  const { key, route, name, icon, collapseName } = details;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onLogout = () => {
    setOpen(false);
    navigate("/logout");
  };

  return (
    <div>
      <button type="button" className="popup-btn" onClick={handleClickOpen}>
        <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
      </button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Do you want to Logout?</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button variant="filledTonal" style={{ color: " #1b66be" }} onClick={handleClose}>
            No
          </Button>
          <Button
            variant="filledTonal"
            style={{ color: "white", background: "#d52d2dd6" }}
            onClick={() => onLogout()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default DialogPopup;
