/* eslint-disable no-nested-ternary */
import React from "react";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OpenPlotForm from "./Forms/OpenPlotForm";

function AssetCreation() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        variant="gradient"
        coloredShadow="info"
        // borderRadius="lg"
        boxShadow="xl"
        mt={3}
        p={2}
      >
        {/* <Chip.Group multiple={false} value={selectedAssetType} onChange={(e)=>setSelectedAssetType(e)}>
          <Chip variant="filled" color="teal" value="Open Plot">
            Open Plot
          </Chip>
          <Chip variant="filled" color="teal" value="Apartment">
            Apartment
          </Chip>
          <Chip variant="filled" color="teal" value="House">
            House
          </Chip>
        </Chip.Group> */}

        <OpenPlotForm />
      </MDBox>
    </DashboardLayout>
  );
}

export default AssetCreation;
