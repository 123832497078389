/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Images
import avatardefault from "assets/images/avatardefault.png";

export default function data() {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  //   const Job = ({ title, description }) => (
  //     <MDBox lineHeight={1} textAlign="left">
  //       <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //         {title}
  //       </MDTypography>
  //       <MDTypography variant="caption">{description}</MDTypography>
  //     </MDBox>
  //   );

  const handleProfileClick = (e) => {
    console.log("Profile clicked");
    console.log(e.target.value);
  };

  const handleAssetClick = (e) => {
    console.log("Asset clicked");
    console.log(e.target.value);
  };

  const handleCreateAssetClick = () => {
    console.log("Create Asset clicked");
  };

  const customers = [1, 2, 3, 4];
  const custs = [];
  let row = {};

  // eslint-disable-next-line array-callback-return
  customers.map((cust) => {
    row = {
      customer: <Author image={avatardefault} name="j" email={cust} />,
      profile_view: (
        <MDButton label="profile" color="info" value={cust} onClick={handleProfileClick}>
          View Profile
        </MDButton>
      ),
      assets_view: (
        <MDButton color="error" value={cust} onClick={handleAssetClick}>
          View Assets
        </MDButton>
      ),
      create: (
        <MDButton color="error" variant="outlined" onClick={handleCreateAssetClick}>
          Create Asset
        </MDButton>
      ),
    };
    custs.push(row);
  });

  return {
    columns: [
      { Header: "", accessor: "customer", align: "left" },
      { Header: "", accessor: "profile_view", align: "left" },
      { Header: "", accessor: "assets_view", align: "center" },
      { Header: "", accessor: "create", align: "center" },
    ],

    // rows: [
    //   {
    //     customer: (
    //       <Author image={avatardefault} name="John Michael" email="john@creative-tim.com" />
    //     ),
    //     profile_view: (
    //       <MDButton label="profile" color="info" onClick={handleProfileClick}>
    //         View Profile
    //       </MDButton>
    //     ),
    //     assets_view: (
    //       <MDButton color="error" onClick={handleAssetClick}>
    //         View Assets
    //       </MDButton>
    //     ),
    //     create: (
    //       <MDButton color="error" variant="outlined" onClick={handleCreateAssetClick}>
    //         Create Asset
    //       </MDButton>
    //     ),
    //   },
    // ],
    rows: custs,
  };
}
