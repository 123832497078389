/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { MultiSelect } from "@mantine/core";

const types = [
  { value: "Plot Cleaning", label: "Plot Cleaning" },
  { value: "Plot Maintenance", label: "Plot Maintenance" },
  { value: "Plot Monitoring", label: "Plot Monitoring" },
  { value: "Fencing", label: "Fencing" },
];

function Demo({ setValue, data }) {
  const services = data.services.map((service) => ({ value: service, label: service }));
  const defaultServicseOpted = services.map((service) => service.value?.service_name);
  useEffect(() => {
    setValue(defaultServicseOpted);
  }, []);

  return (
    <MultiSelect
      data={types}
      label="Select services to be provided"
      placeholder="Services"
      defaultValue={defaultServicseOpted}
      onChange={setValue}
    />
  );
}

export default Demo;
