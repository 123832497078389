import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";

import TableData from "../TableData";

function Base() {
  return (
    <MDBox width="100%">
      <MDBox display="flex" flexDirection="column" mt={2}>
        <MDBox ml={1} mr={1} mb={1} p={1}>
          <MDTypography style={{ fontSize: "14px", fontWeight: "400", color: "#af97b1" }}>
            Pending Requests
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableData />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Base;
