/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from "react";
import { Table, Badge, Checkbox } from "@mantine/core";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { fetchAllAssignedProperties } from "services/api.agent.service";
import Loader from "./Loader";

// import { ExclamationCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

// import RequestedAssetModal from "modals/RequestedAssetModal";

export const Selection = () => <Checkbox />;

// eslint-disable-next-line react/prop-types
export const Name = ({ name, email }) => (
  <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
    <MDTypography style={{ fontSize: "14px", fontWeight: "500", color: "#2f2f2f" }}>
      {name}
    </MDTypography>
    <MDTypography style={{ fontSize: "12px", color: "#b3b3b3" }}>{email}</MDTypography>
  </MDBox>
);

// eslint-disable-next-line react/prop-types
export const Address = ({ addressLine1 }) => (
  <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
    <MDTypography style={{ fontSize: "12px", fontWeight: "400", color: "#2e2e2e" }}>
      {addressLine1}
    </MDTypography>
  </MDBox>
);

// eslint-disable-next-line react/prop-types
const AssetType = ({ type }) => {
  return type === "Open Plot" ? (
    <Badge color="teal" size="xs" radius="xs">
      {type}
    </Badge>
  ) : type === "Apartment" ? (
    <Badge color="red" size="xs" radius="xs" variant="outline">
      {type}
    </Badge>
  ) : (
    <Badge color="blue" size="xs" radius="xs" variant="outline">
      {type}
    </Badge>
  );
};

// eslint-disable-next-line react/prop-types
const ServiceOpted = ({ serviceName, coloring }) => {
  return (
    <Badge variant="dot" color={coloring} size="sm" radius="xs">
      {serviceName}
    </Badge>
  );
};
const UpdateDue = ({ dueDate }) => {
  if (dueDate === null) {
    const nxt_updt = "NA";
    return (
      <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        <Badge color="gray" size="sm" radius="xs" variant="outline">
          {nxt_updt}
        </Badge>
      </MDBox>
    );
  }
  const nxt_updt = new Date(dueDate).toLocaleDateString();
  //   extract date month and year
  const date = new Date(dueDate).getDate();
  const month = new Date(dueDate).getMonth();
  const year = new Date(dueDate).getFullYear();
  console.log(date, month, year);
  //   get current date month and year
  const current_date = new Date().getDate();
  const current_month = new Date().getMonth();
  const current_year = new Date().getFullYear();
  if (current_year === year && current_month === month && current_date === date) {
    return (
      <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        <Badge color="red" size="sm" radius="xs" variant="outline">
          {nxt_updt}
        </Badge>
      </MDBox>
    );
  }

  return (
    <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      <Badge color="violet" size="sm" radius="xs" variant="outline">
        {nxt_updt}
      </Badge>
    </MDBox>
  );
};

// eslint-disable-next-line react/prop-types, no-unused-vars
const Action = () => {
  return (
    <MDBox>
      <MDTypography style={{ fontSize: "10px", borderBottom: "1px solid", color: "red" }}>
        Update
      </MDTypography>
    </MDBox>
  );
};

// eslint-disable-next-line react/prop-types
const AssetName = ({ name }) => (
  <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
    <MDTypography style={{ fontSize: "12px", fontWeight: "400", color: "#2e2e2e" }}>
      {name}
    </MDTypography>
  </MDBox>
);

function Base({ setViewUpdate, setData }) {
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleRowClick = (e) => {
    setData(e);
    setViewUpdate(true);
  };

  useEffect(() => {
    setLoading(true);
    fetchAllAssignedProperties().then((res) => {
      setElements(res);
      setLoading(false);
    });
  }, []);

  const rows = elements.map((element) => (
    <tr key={element.id} onClick={() => handleRowClick(element)} style={{ cursor: "pointer" }}>
      <td>
        <Name
          name={element.asset_service?.customer_name}
          email={element.asset_service?.customer_email}
        />
      </td>
      <td>
        <AssetName name={element.asset_name} />
      </td>
      <td>
        <Address addressLine1={element.asset_address?.location_name} />
      </td>
      <td>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <AssetType type={element.asset_service?.asset_type} />
        </MDBox>
      </td>
      <td>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <ServiceOpted serviceName={element.service_name} coloring="violet" />
        </MDBox>
      </td>
      <td>
        <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <UpdateDue dueDate={element.asset_service?.next_update} />
        </MDBox>
      </td>
      <td>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <Action />
        </MDBox>
      </td>
    </tr>
  ));

  return (
    <MDBox>
      <Table highlightOnHover>
        <thead>
          <tr style={{ backgroundColor: "#f4f8f9" }}>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Customer
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Asset Name
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Address
                </MDTypography>
              </MDBox>
            </th>

            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Asset Type
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Service
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Update Due
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Action
                </MDTypography>
              </MDBox>
            </th>
          </tr>
        </thead>
        {!loading && <tbody>{rows}</tbody>}
      </Table>
      {loading && <Loader />}
    </MDBox>
  );
}

export default Base;
