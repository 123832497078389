/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";

import { fetchUserProfile, createUserProfile } from "services/api.service";
import InfoBar from "./InfoBar";
import AddressBar from "./AddressBar";
import SettingsBar from "./ProfileSettingsFile";

function Base({ handleProfileStatusChange }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleMobileChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipcode] = useState("");
  const gender = "";
  const uniqueId = "";
  const companyName = "";

  const handleAddress1 = (e) => {
    setAddressLine1(e.target.value);
  };

  const handleAddress2 = (e) => {
    setAddressLine2(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleZipCodeChange = (e) => {
    setZipcode(e.target.value);
  };

  const handleSave = () => {
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      uniqueId,
      companyName,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      zipCode,
    };
    createUserProfile(data).then((res) => {
      if (res) {
        handleProfileStatusChange();
      }
    });
  };

  useEffect(() => {
    fetchUserProfile().then((res) => {
      setFirstName(res.data?.first_name);
      setEmail(res.email);
    });
  }, []);
  return (
    <MDBox py={3}>
      <InfoBar
      className="profiletest"
        firstName={firstName}
        lastName={lastName}
        email={email}
        mobile={phoneNumber}
        handleNameChange={handleNameChange}
        handleLastNameChange={handleLastNameChange}
        handleMobileChange={handleMobileChange}
      />
      <Grid container spacing={2}>
        <AddressBar
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          state={state}
          country={country}
          zipCode={zipCode}
          handleAddress1={handleAddress1}
          handleAddress2={handleAddress2}
          handleCityChange={handleCityChange}
          handleStateChange={handleStateChange}
          handleCountryChange={handleCountryChange}
          handleZipCodeChange={handleZipCodeChange}
        />
        <SettingsBar handleSave={handleSave} />
      </Grid>
    </MDBox>
  );
}

export default Base;
