import React from "react";

import MDBox from "components/MDBox";
import { Skeleton } from "@mantine/core";

function Base() {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
    <MDBox width="100%">
      <MDBox display="flex" flexDirection="row" alignItems="center" mb={0.5} mt={1}>
        <MDBox display="flex" flexDirection="column" justifyContent="center" ml={5} mr={5}>
          <MDBox ml={1} mb={0.5}>
            <Skeleton height={8} width={75} radius="xl" />
          </MDBox>
          <MDBox mt={0.5}>
            <Skeleton height={8} width={100} radius="xl" />
          </MDBox>
        </MDBox>
        <MDBox display="flex" mr={2.5}>
          <Skeleton height={12} width={150} radius="xl" />
        </MDBox>
        <MDBox display="flex" mr={2.5}>
          <Skeleton height={12} width={125} radius="xl" />
        </MDBox>
        <MDBox display="flex" mr={2.5}>
          <Skeleton height={12} width={125} radius="xl" />
        </MDBox>
        <MDBox display="flex" mr={2.5}>
          <Skeleton height={12} width={125} radius="xl" />
        </MDBox>
        <MDBox display="flex" ml={1} mr={2.5}>
          <Skeleton height={12} width={125} radius="xl" />
        </MDBox>
        <MDBox display="flex" ml={3.75}>
          <Skeleton height={12} width={50} radius="xl" />
        </MDBox>
      </MDBox>
      <Skeleton height={2} radius="xl" />
    </MDBox>
  ));
}

export default Base;
