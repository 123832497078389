import React from "react";

import { Select } from "@mantine/core";

// eslint-disable-next-line react/prop-types
function Base({ recurrenceValue, setRecurrenceValue }) {
  return (
    <Select
      placeholder="Recurrence Period"
      label="Recurrence Period (Optional)"
      defaultValue={recurrenceValue}
      style={{ width: "100%" }}
      data={["NA", "2 weeks", "1 month", "3 months", "6 months", "1 year"]}
      styles={(theme) => ({
        item: {
          // applies styles to selected item
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.teal[9] : theme.colors.teal[1],
              color: theme.colorScheme === "dark" ? theme.white : theme.colors.teal[9],
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          "&[data-hovered]": {},
        },
      })}
      onChange={(v) => setRecurrenceValue(v)}
    />
  );
}

export default Base;
