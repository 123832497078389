import React from "react";

import MDBox from "components/MDBox";

function TODO() {
  return (
    <MDBox width="100%" border="1px solid">
      TODO
    </MDBox>
  );
}

export default TODO;
