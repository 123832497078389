import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import ExploreCard from "./ExploreServices";
import ProfileCompletionRef from "./ProfileCompletetionRefereeCard";
// import MaintainCard from "../MaintainCard";
import ProfileProgress from "./ProfileProgressView";
// import SAIntroCard from "../SAIntroCard";

function ProfileCompletionCard() {
  return (
    <MDBox
      display="flex"
      borderRadius="lg"
      bgColor="#e6f4f8"
      mt={2}
      mb={2}
      p={1}
      //   border="1px solid black"
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MDBox display="flex" flexDirection="column">
                <MDBox display="flex" flexDirection="row" alignItems="baseline">
                  <MDTypography
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                      fontSize: "2rem",
                    }}
                  >
                    Welcome,
                  </MDTypography>
                </MDBox>
                <ProfileProgress />
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <ProfileCompletionRef />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container spacing={1}>
            <Grid item>
              <MDBox ml={1} mr={1}>
                <ExploreCard />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ProfileCompletionCard;
