import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import FeedIcon from "@mui/icons-material/Feed";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// import AgentDashboard from "agentLayouts/AgentDashboard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { tapUserLite } from "services/api.service";
// import { tapUserRole } from "services/api.agent.service";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import TapUser from "./components/TapUser";
import NotificationItem from "./components/Projects/components/NotificationItem";

function Base() {
  const [isNewUser, setIsNewUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const notificationItems = [
    {
      title: "Plot Monitoring services",
      description:
        "We are gladd to inform you that we are starting to offer plot monitoring services in Hyderabad from this month.",
      color: "red",
    },
    // Add more notification items as needed
  ];
  // useEffect(() => {
  //   setLoading(true);
  //   tapUserRole().then((res) => {
  //     if (res.isAgent && isAgent === false) {
  //       setIsAgent(true);
  //       setLoading(false);
  //     } else if (res.isCustomer && res.isNewUser) {
  //       setIsNewUser(true);
  //       setLoading(false);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    setLoading(true);
    tapUserLite().then((res) => {
      setIsNewUser(res.isNewUser);
      setLoading(false);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {!loading &&
          (isNewUser ? (
            <TapUser />
          ) : (
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} lg={8}>
                  <Projects />
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                  <OrdersOverview />
                </Grid>
              </Grid>
            </MDBox>
          ))}
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          {/* // dynamic */}
          <Card>
            <MDBox display="flex" alignItems="center" pt={3} px={3}>
              <FeedIcon sx={{ color: "red" }} />
              <MDTypography sx={{ color: "red" }} px={2} variant="h6" fontWeight="medium">
                News
              </MDTypography>
            </MDBox>
            <MDBox px={3} mt={3}>
              {notificationItems.map((item) => (
                <NotificationItem key="test" {...item} />
              ))}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Base;
