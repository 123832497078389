import React from "react";

import { Select } from "@mantine/core";

// eslint-disable-next-line react/prop-types
function Base({ setRecurrenceValue, vald }) {
  return (
    <Select
      placeholder="Edit Recurrence Period"
      defaultValue={vald}
      style={{ width: "100%" }}
      // data={["NA", "2 weeks", "1 month", "3 months", "6 months", "1 year"]}
      data={[
        { value: "NA", label: "NA" },
        { value: "2W", label: "2 weeks" },
        { value: "1M", label: "1 month" },
        { value: "3M", label: "3 months" },
        { value: "6M", label: "6 months" },
        { value: "1Y", label: "1 year" },
      ]}
      styles={(theme) => ({
        item: {
          // applies styles to selected item
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.teal[9] : theme.colors.teal[1],
              color: theme.colorScheme === "dark" ? theme.white : theme.colors.teal[9],
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          "&[data-hovered]": {},
        },
      })}
      onChange={(v) => setRecurrenceValue(v)}
    />
  );
}

export default Base;
