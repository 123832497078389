import React, { useState, useEffect } from "react";
import { Loader } from "@mantine/core";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { createUser } from "services/api.service";
import { tweekAgentRole } from "services/api.agent.service";

function Base() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [load, setLoad] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleSubmit = () => {
    setLoad(true);
    const data = {
      name,
      email,
      password,
      re_password: rePassword,
    };

    createUser(data)
      .then((res) => {
        if (res) {
          return tweekAgentRole(res.result.email);
        }
        throw new Error("User creation failed");
      })
      .then((response) => {
        setIsError(!response);
        setLoad(false);
        setIsCreated(response);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  useEffect(() => {
    let timeout;
    if (isCreated || isError) {
      timeout = setTimeout(() => {
        setIsCreated(false);
        setIsError(false);
        setLoad(false);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [isCreated, isError]);

  return (
    <MDBox mt={3} display="flex" justifyContent="center" alignItems="center">
      <MDBox display="flex" flexDirection="column" width="70%" component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Name"
            variant="standard"
            value={name}
            onChange={(e) => handleInputChange(e, setName)}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            variant="standard"
            value={password}
            onChange={(e) => handleInputChange(e, setPassword)}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Confirm Password"
            variant="standard"
            value={rePassword}
            onChange={(e) => handleInputChange(e, setRePassword)}
            fullWidth
          />
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDButton variant="gradient" onClick={handleSubmit} color="error" fullWidth>
            {load ? <Loader color="indigo" size="sm" /> : "INVITE"}
          </MDButton>
        </MDBox>
        {isCreated && <MDTypography variant="subtitle1">Agent Created Successfully!</MDTypography>}
        {isError && (
          <MDTypography variant="subtitle1">Something went wrong, please try again!</MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
}

export default Base;
