import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Base() {
  //   const { code, state } = useParams();
  const navigate = useNavigate();
  const paramString = window.location.search;
  const urlParams = new URLSearchParams(paramString);
  const code = urlParams.get("code");
  const state = urlParams.get("state");

  //   const [dp, setDp] = useState("");

  const getUser = (token) => {
    fetch(`http://localhost:8000/v1/details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => localStorage.setItem("user", JSON.stringify(data)));
      window.close();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    fetch(`http://localhost:8000/auth/o/google-oauth2/`, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      body: `code=${code}&state=${state}`,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.access) {
          localStorage.setItem("auth_token", data.access);
          //  AsyncStorage.setItem('refresh_token', data.refresh);
          getUser(data.access);
          //  AsyncStorage.setItem('first_name', data.user.userName);
        } else {
          console.log("error");
        }
      });
  }, []);

  return <div>Redirecting...</div>;
}

export default Base;
