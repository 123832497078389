/* eslint-disable react/prop-types */
import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";

function Requested({ requestedServices }) {
  return (
    <MDBox>
      {requestedServices.length === 0 ? (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MDTypography style={{ fontSize: "14px", fontWeight: "400", color: "#2e2e2e" }}>
            No Requested Services
          </MDTypography>
        </MDBox>
      ) : (
        requestedServices.map((item) => (
          <MDBox
            display="flex"
            flexDirection="row"
            shadow="lg"
            borderRadius="lg"
            alignItems="center"
            color="white"
            variant="gradient"
            justifyContent="flex-start"
            mb={2}
            style={{
              backgroundColor: "#edf5f9",
              borderRadius: 4,
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
                flexGrow: 1,
                backgroundColor: "#edf5f9",
                borderRadius: 4,
                alignItems: "center",
              }}
            >
              <MDBox
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexGrow: 1,
                  backgroundColor: "#edf5f9",
                  borderRadius: 4,
                  alignItems: "center",
                }}
              >
                <MDTypography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#2e2e2e",
                    marginLeft: "10px",
                  }}
                >
                  {item.service?.serviceName}
                </MDTypography>
              </MDBox>
            </Card>
          </MDBox>
        ))
      )}
    </MDBox>
  );
}

export default Requested;
