/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
// Fetch customer assets by id
export const fetchAssets = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customer/${id}/assets`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

//  update unique asset by id
export const updateAsset = (id, images) => {
  // eslint-disable-next-line array-callback-return
  const formdata = new FormData();
  // eslint-disable-next-line no-plusplus

  images.forEach((image) => {
    formdata.append(`images`, image);
  });

  const requestOptions = {
    method: "POST",
    body: formdata,
  };

  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/update/${id}`, requestOptions)
    .then((response) => response.text())
    .then((data) => data)
    .catch((error) => console.log("error", error));
  return result;
};

export const fetchUserProfile = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customer/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const createOpenPlotAsset = (id, data) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customer/${id}/create/asset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: data.assetName,
      location_name: data.location_name,
      land_mark: data.land_mark,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      zip_code: data.zip_code,
      assetType: data.assetType,
      size_in_yards: data.sizeInYards,
      site_facing: data.site_facing,
      survey_number: data.survey_number,
      registration_number: data.registration_number,
      sub_registrar_office: data.sub_registrar_office,
      venture: data.venture,
      dimensions_east: data.east,
      dimensions_west: data.west,
      dimensions_south: data.south,
      dimensions_north: data.north,
    }),
  })
    .then((response) => response.json())
    // eslint-disable-next-line no-shadow
    .then((data) => data);
  return result;
};

export const getAllNotifications = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/notifications`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetDetails = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchCustomerIdAssetIDByNotificationID = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/get_asset_customer_id/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetDetailsByNotificationId = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/review/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetDetailStatus = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/user/asset/${id}/details`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const approveAsset = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}/approve`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetStatus = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}/status`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const approveService = (aid, sid) => {
  const result = fetch(
    `${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${aid}/services/${sid}/approve`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const getAssetServices = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}/services`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const declineAsset = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}/decline`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

//  asset requests
// admin asset requsets table view data fetch
export const fetchAssetRequests = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/all/assets/requests`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// Get agents info (all)
export const fetchAgentsInfo = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/agents/info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// agent association with assets and services
export const associateAssetAgentServices = (asset_id, value, agent_name) => {
  const id = asset_id;
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/add/asset/${id}/services/agent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent_name,
      service_name: value,
    }),
  })
    .then((response) => response.json())
    .then((dt) => dt);
  return result;
};
