/** 

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React layouts
import Dashboard from "adminLayouts/dashboard";
import CreateCustomer from "adminLayouts/customer";
import SearchCustomer from "adminLayouts/customer/SearchCustomer";
import Logout from "layouts/logout";
import Assets from "adminLayouts/assets";
import Profile from "adminLayouts/profile";
import CreateAsset from "adminLayouts/createAsset";
import AssetDetails from "adminLayouts/assetDetails";
import AssetsRequests from "adminLayouts/AssetRequests";
// import Configurator from "examples/Configurator";
import AssetView from "layouts/assetsView";
// import Test from "adminLayouts/test";
// import SignIn from "layouts/authentication/sign-in";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "User",
    key: "create/customer",
    icon: <Icon fontSize="small">add_circle</Icon>,
    route: "/create/customer",
    component: <CreateCustomer />,
  },
  {
    type: "collapse",
    name: "Requests",
    key: "assets",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/assets",
    component: <AssetsRequests />,
  },
  // {
  //   type: "collapse",
  //   name: "config",
  //   key: "config",
  //   route: "/config",
  //    icon: <Icon fontSize="small">search</Icon>,
  //   component: <Configurator />,
  // },
  // {
  //   type: "collapse",
  //   name: "Create Asset",
  //   key: "create/asset",
  //   icon: <Icon fontSize="small">add_circle</Icon>,
  //   route: "/create/asset",
  //   component: <CreateAsset />,
  // },
  {
    type: "collapse",
    name: "Search Customers",
    key: "search/customers",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/search/customers",
    component: <SearchCustomer />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
  },

  //   Routes
  {
    type: "route",
    name: "Assets",
    key: "Assets",
    route: "customer/:id/assets",
    component: <AssetView />,
  },

  {
    type: "route",
    name: "Asset",
    key: "Asset",
    route: "customer/:cid/assets/:id",
    component: <Assets />,
  },
  {
    type: "route",
    name: "Profile",
    key: "Profile",
    route: "customer/:id",
    component: <Profile />,
  },
  {
    type: "route",
    name: "Create Asset",
    key: "Create Asset",
    route: "customer/:id/create/asset",
    component: <CreateAsset />,
  },
  {
    type: "route",
    name: "Asset Details",
    key: "Asset Details",
    route: "customer/:cid/assets/:id/details",
    component: <AssetDetails />,
  },
  // {
  //   type: "collapse",
  //   name: "Test",
  //   key: "test",
  //   route: "test",
  //   component: <Test />,
  // },
];

export default routes;
