import React from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

const Ribbon = styled("div")({
  position: "absolute",
  top: "-7px",
  right: "-7px",
  backgroundColor: "grey",
  color: "white",
  padding: "0 10px",
  fontSize: "10px",
  transformOrigin: "top right",
  zIndex: 1,
});

const Tail = styled("div")({
  position: "absolute",
  left: "100%",
  top: "12px",
  transform: "rotate(225deg)",
  marginTop: "-5px",
  borderTop: "5px solid transparent",
  borderLeft: "5px solid grey",
  borderBottom: "5px solid transparent",
});
const RibbonCard = styled(Card)(() => ({
  position: "relative",
  padding: "16px",
}));
// eslint-disable-next-line react/prop-types
function StyledRibbonCard({ children, ribbonText }) {
  return (
    <RibbonCard ribbonText={ribbonText}>
      {ribbonText && (
        <>
          <Ribbon>{ribbonText}</Ribbon>
          <Tail />
        </>
      )}
      {children}
    </RibbonCard>
  );
}

export default StyledRibbonCard;
