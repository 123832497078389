/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";

import Button from "@mui/material/Button";
import MDInput from "components/MDInput";

// eslint-disable-next-line react/prop-types
const UploadAndDisplayImage = ({ selectedImage, setSelectedImage }) => {
  return (
    <div>
      {selectedImage && (
        <div>
          <img alt="not found" width="100%" src={URL.createObjectURL(selectedImage)} />
          <Button onClick={() => setSelectedImage(null)}>Remove</Button>
        </div>
      )}
      <MDInput
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage(event.target.files[0]);
        }}
      />
    </div>
  );
};

export default UploadAndDisplayImage;
