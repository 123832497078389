import React from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import Notifications from "./components/Notifications";

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Notifications />
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
