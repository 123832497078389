/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { fetchAssets as fetchAssetsById } from "services/api.admin.service";

import Grid from "@mui/material/Grid";

import bg_sign_in_basic from "assets/images/bg-sign-in-basic.jpeg";
import { fetchAssets } from "services/api.service";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Skeleton } from "@mantine/core";
import ProjectCards from "./components/ProjectCards";

function AssetView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  //   const assets = [];
  const base64Prefix = "data:image/png;base64,";
  const handleClick = () => {
    navigate("/asset");
  };

  useEffect(() => {
    setLoading(true);
    if(id){
      fetchAssetsById(id).then((res) => {
        setAssets(res);
        setLoading(false);
        // }).catch((err) => {
        //     console.log(err);
        // }).finally(() => {
        //     setLoading(false);
      });
    } else {
      fetchAssets().then((res) => {
        setAssets(res);
        setLoading(false);
        // }).catch((err) => {
        //     console.log(err);
        // }).finally(() => {
        //     setLoading(false);
      });
    }
    
  }, []);
  //   const image = "https://source.unsplash.com/random/100x100";
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} sx={{ alignItems: "center" }}>
          {loading &&
            [1, 2, 3].map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item}>
                {[1, 2, 3].map((itd) => (
                  <div key={itd}>
                    <Skeleton height={200} ml="md" mt="md" />
                  </div>
                ))}
              </Grid>
            ))}
          {!loading &&
            (assets.length > 0 ? (
              assets.map((asset) => (
                <Grid item key={asset.id} xs={3} md={3} lg={3}>
                  {asset.asset_image !== null ? (
                    <ProjectCards
                      image={`${base64Prefix}${asset.primary_image}`}
                      label={asset.asset_type}
                      title={asset.asset_name}
                      description={asset.city}
                      action={{
                        type: "internal",
                        route: id ? `/customer/${id}/assets/${asset.id}` : `/assets/${asset.id}`,
                        color: "primary",
                        label: "View Asset",
                      }}
                    />
                  ) : (
                    <ProjectCards
                      image={bg_sign_in_basic}
                      label={asset.asset_type}
                      title={asset.asset_name}
                      description={asset.city}
                      action={{
                        type: "internal",
                        route: id ? `/customer/${id}/assets/${asset.id}` : `/assets/${asset.id}`,
                        color: "primary",
                        label: "View Asset",
                      }}
                    />
                  )}
                </Grid>
              ))
            ) : (
              <MDBox
                mt={20}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <MDTypography>No Assets Created yet. </MDTypography>
                <MDButton color="error" onClick={handleClick}>
                  Create Asset
                </MDButton>
              </MDBox>
            ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AssetView;
