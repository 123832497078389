import React from "react";

import MDBox from "components/MDBox";

function Recents() {
  return (
    <MDBox width="100%" border="1px solid">
      Recents
    </MDBox>
  );
}

export default Recents;
