/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// import { Divider } from "@mantine/core";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "examples/Tables/DataTable";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
// import MDInput from "components/MDInput";

// import { searchCustomers } from "services/api.service";
import { searchCustomersByName, searchCustomersByEmail } from "services/api.service";
import avatardefault from "assets/images/avatardefault.png";
import customersTableData from "./data/customersTableData";

// Images

function Base() {
  const navigate = useNavigate();
  const { columns } = customersTableData();
  //   const { columns: pColumns, rows: pRows } = projectsTableData();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [customers, setCustomers] = useState([]);
  const [rows, setRows] = useState([]);

  //   const handleNameInput = (e) => {
  //     setName(e.target.value);
  //   };

  //   const handleEmailInput = (e) => {
  //     // check until the given input is of eemail type or not
  //     if (e.target.value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
  //       setEmail(e.target.value);
  //     }
  //   };

  useEffect(() => {
    if (email !== "") {
      setEmail("");
    }
    searchCustomersByName(name).then((res) => {
      setCustomers(res);
    });
  }, [name]);

  useEffect(() => {
    if (name !== "") {
      setName("");
    }
    searchCustomersByEmail(email).then((res) => {
      setCustomers(res);
    });
  }, [email]);

  const handleProfileClick = (e) => {
    const id = e.target.value;
    navigate(`/customer/${id}`);
  };

  const handleAssetClick = (e) => {
    // console.log(e.target.value);
    const id = e.target.value;
    navigate(`/customer/${id}/assets`);
  };

  const handleCreateAssetClick = (e) => {
    const id = e.target.value;
    console.log(id);
    navigate(`/customer/${id}/create/asset`);
  };

  //   useEffect(() => {
  //     setTimeout(() => {
  //       searchCustomers(name, email).then((res) => { 
  //         console.log(res);
  //       });
  //     }, 1000);
  //   }, [name, email]);

  const Author = ({ image, custName, custEmail }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={custName} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {custName}
        </MDTypography>
        <MDTypography variant="caption">{custEmail}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const custs = [];
  let row = {};

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    customers.map((cust) => {
      row = {
        customer: (
          <Author
            image={avatardefault}
            custName={cust.customerName}
            custEmail={cust.customerEmail}
          />
        ),
        profile_view: (
          <MDButton
            label="profile"
            color="info"
            value={cust.customerID}
            onClick={handleProfileClick}
            variant="outlined"
          >
            View Profile
          </MDButton>
        ),
        assets_view: (
          <MDButton color="error" variant="outlined" value={cust.customerID} onClick={handleAssetClick}>
            View Assets
          </MDButton>
        ),
        create: (
          <MDButton
            color="success"
            variant="outlined"
            value={cust.customerID}
            onClick={handleCreateAssetClick}
          >
            Create Asset
          </MDButton>
        ),
      };
      custs.push(row);
    });
    setRows(custs);
  }, [customers]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Customers
                </MDTypography>
              </MDBox>
              {/* <MDBox
                p={1}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <MDBox p={1} display="flex" alignItems="center">
                  <MDTypography variant="body1" pr={2}>
                    Name :
                  </MDTypography>
                  <MDInput type="text" placeholder="Search by Name" onChange={handleNameInput} />
                </MDBox>
                <Divider orientation="vertical" />
                <MDBox p={1} display="flex" alignItems="center">
                  <MDTypography variant="body1" type="email" pr={2}>
                    Email :
                  </MDTypography>
                  <MDInput type="email" placeholder="Search by Email" onChange={handleEmailInput} />
                </MDBox>
              </MDBox> */}
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Base;
