import React from "react";

import { Modal } from "@mantine/core";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// eslint-disable-next-line react/prop-types
function ProfileEditModal({ editMode, editHandler }) {
  return (
    <Modal
      centered
      opened={editMode}
      onClose={editHandler}
      title="Edit Profile"
      overlayOpacity={0.55}
      overlayBlur={5}
      overlayColor="black"
      size="sm"
      radius="lg"
      bg="black"
      zIndex={2000}
    >
      {/* Modal content */}
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox mb={2}>
            <MDInput
              type="email"
              disabled
              label="Email"
              textColor="red"
              fullWidth
              autoComplete="off"
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput label="Full Name" textColor="red" fullWidth autoComplete="off" />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={2} p={1}>
                <MDInput
                  type="tel"
                  label="Mobile No."
                  textColor="red"
                  fullWidth
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={2} p={1}>
                <MDInput label="Location" textColor="red" fullWidth autoComplete="off" />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox mb={2}>
            <MDButton
              variant="contained"
              color="error"
              fullWidth
              size="small"
              onClick={editHandler}
            >
              Save
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ProfileEditModal;
