/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// import { Link } from "react-router-dom";

import { CardMedia } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import { Skeleton } from "@mantine/core";

import { fetchAssetDetails } from "services/api.agent.service";
import bg_sign_in_basic from "assets/images/bg-sign-in-basic.jpeg";

function AssetInfoCard({ data, setViewAssetDetails }) {
  const base64Prefix = "data:image/png;base64,";
  const [assetDetails, setAssetDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const handleViewAssetDetailsClick = () => {
    setViewAssetDetails(true);
  };

  useEffect(() => {
    setLoading(true);
    fetchAssetDetails(data?.asset_id).then((res) => {
      setAssetDetails(res);
      setLoading(false);
    });
  }, []);
  const src =
    assetDetails.primary_image !== "NONE"
      ? base64Prefix + assetDetails.primary_image
      : bg_sign_in_basic;
  return (
    <MDBox width="100%" bgColor="#e6f4f8" display="flex" height={100} shadow="md">
      <MDBox
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        sx={{ height: "100%" }}
      >
        {loading ? (
          <MDBox mt={2} mr={2} borderRadius="lg">
            <Skeleton height="100%" width={300} />
          </MDBox>
        ) : (
          <CardMedia
            component="img"
            sx={{ width: 500, height: "100%", borderRadius: 0, padding: 0, margin: 0 }}
            image={src}
            alt="Asset Image"
          />
        )}
        <MDBox display="flex" width="100%" alignItems="center">
          <MDBox display="flex" flexDirection="column" alignItems="flex-start" marginLeft={2}>
            {loading ? (
              <Skeleton height="100%" width={200} marginTop={0} marginLeft={0} />
            ) : (
              <MDTypography variant="h6" component="div">
                {assetDetails.name}
              </MDTypography>
            )}
            {loading ? (
              <Skeleton height={20} width={100} radius="lg" />
            ) : (
              <MDTypography variant="subtitle2" color="text.secondary" component="p">
                {assetDetails.address?.location_name}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <MDBox display="flex" width="100%" alignItems="center" justifyContent="flex-end">
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" width="100%" mr={2}>
            <MDButton
              variant="outlined"
              color="error"
              style={{
                width: "159px",
              }}
              endIcon={<SendIcon />}
              onClick={handleViewAssetDetailsClick}
            >
              View Details
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default AssetInfoCard;
