/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";

// import { useParams } from "react-router-dom";

import { Grid, Badge } from "@mantine/core";
import { Card, CardMedia } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import GeneralInfo from "layouts/assetDetails/GeneralInfo";
import AssetServices from "layouts/assetDetails/AssetServices";
// import DataTable from "layouts/assetDetails/DataTable";

import bg_sign_in_basic from "assets/images/bg-sign-in-basic.jpeg";

import { fetchAssetDetails } from "services/api.service";

function AssetDetails({ data }) {
  const [assetDetails, setAssetDetails] = useState({});
  const base64Prefix = "data:image/png;base64,";
  const src =
    assetDetails.primary_image === "NONE"
      ? bg_sign_in_basic
      : base64Prefix + assetDetails.primary_image;

  useEffect(() => {
    fetchAssetDetails(data.asset_id).then((res) => {
      setAssetDetails(res);
    });
  }, []);

  // Grid col Service Modal click handler
  // const handleServiceModalClick = () => {
  //   useEffect(() => {
  //     setServiceModalOpen((prev) => !prev);
  //   }, [serviceModalOpen]);
  // };

  // console.log(serviceModalOpen);

  return (
    <MDBox color="white" style={{ height: "100%" }} mt={2}>
      <Grid columns={30}>
        <Grid.Col span={22}>
          <Card
            sx={{
              display: "flex",
              marginTop: 1,
              margin: 0,
              padding: 0,
              borderRadius: 0,
            }}
            xs={12}
            md={10}
            lg={10}
          >
            <MDBox color="white" display="flex" flexDirection="row" width="100%" height={100}>
              <CardMedia
                component="img"
                sx={{ width: 300, height: "100%", margin: 0, padding: 0, borderRadius: 0 }}
                image={src}
                alt="Asset Image"
              />
              <MDBox
                color="white"
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <MDBox display="flex" width="100%">
                  <MDBox display="flex" width="100%" ml={1} mr={1} mt={1}>
                    <MDBox width="100%">
                      <MDTypography variant="h6" component="div">
                        {assetDetails.name}
                      </MDTypography>
                      <MDBox display="flex" flexDirection="row">
                        <MDTypography variant="subtitle2" component="div" sx={{ fontSize: "12px" }}>
                          {assetDetails.address?.address1},&nbsp;
                        </MDTypography>
                        <MDTypography variant="subtitle2" component="div" sx={{ fontSize: "12px" }}>
                          {assetDetails.address?.city},&nbsp;
                        </MDTypography>
                        <MDTypography variant="subtitle2" component="div" sx={{ fontSize: "12px" }}>
                          {assetDetails.address?.land_mark},&nbsp;
                        </MDTypography>
                        <MDTypography variant="subtitle2" component="div" sx={{ fontSize: "12px" }}>
                          {assetDetails.address?.location_name}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox>
                      {assetDetails.approval_status === true && (
                        <Badge variant="gradient" gradient={{ from: "teal", to: "lime", deg: 105 }}>
                          APPROVED
                        </Badge>
                      )}
                      {assetDetails.approval_status === false && (
                        <Badge
                          variant="gradient"
                          gradient={{ from: "#ed6ea0", to: "#ec8c69", deg: 35 }}
                        >
                          PENDING
                        </Badge>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
          <MDBox sx={{ paddingTop: 1 }}>
            <GeneralInfo assetDetailsData={assetDetails} />
          </MDBox>
        </Grid.Col>
        <Grid.Col span={8}>
          {/* Services section */}
          <AssetServices />
        </Grid.Col>
      </Grid>
      <MDBox mt={2} mb={2}>
        <MDTypography
          variant="body"
          style={{
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "left",
            padding: 4,
            marginLeft: 4,
          }}
        >
          General Information
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default AssetDetails;
