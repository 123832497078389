/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";
import adminRoutes from "adminRoutes";
import agentRoutes from "agentRoutes";
// import newUserroutes from "newUserroutes";
// import AdminRoutes from "adminRoutes";

import { useMaterialUIController, setMiniSidenav, setBypass } from "context";
// eslint-disable-next-line no-unused-vars
import { userTokenCheck, fetchUserDetails } from "services/api.service";
import Reset from "layouts/authentication/reset-password/cover";
import Footers from "layouts/authentication/components/Footer";
import './global.css';
import Login from "./layouts/authentication/sign-in";
import Logout from "./layouts/logout/index";
import SignUp from "./layouts/authentication/sign-up";
import VerifiedEmail from "./layouts/authentication/verifiedEmail";
import ResetConformPassword from "./layouts/authentication/reset-conform-password/index";

import PushInvite from "./layouts/authentication/inboxCheck";
import GoogleSignIn from "./layouts/authentication/SocialAuthRedirection";
// import Logout from "layouts/logout";

// Material Dashboard 2 React contexts

// Images
import brandDark from "./assets/images/logo-ct-dark.png";

// import { useGlobalState } from "index";

export default function App() {
  // const navigate = useNavigate();

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, sidenavColor, layout, bypass, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isAgent, setIsAgent] = useState(false);

  useEffect(() => {
    const checkUserToken = async () => {
      // eslint-disable-next-line no-unneeded-ternary
      const tokenValid = localStorage.getItem("token") ? true : false; // await userTokenCheck();
      if (tokenValid) {
        const userDetails = await fetchUserDetails();
        console.log(userDetails)
        if (userDetails?.role === 'admin') {
          setIsAdmin(true);
          setIsCustomer(false);
          setIsAgent(false);
          setBypass(dispatch, true);
          localStorage.setItem("role", "admin");
        } else if (userDetails?.role === "agent") {
          setIsAgent(true);
          setIsCustomer(false);
          setIsAdmin(false);
          setBypass(dispatch, true);
          localStorage.setItem("role", "agent");
        } else {
          setIsCustomer(true);
          setIsAdmin(false);
          setIsAgent(false);
          setBypass(dispatch, true);
          localStorage.setItem("role", "customer");
        }
      } else {
        setBypass(dispatch, false);
        // Handle the case when the token is not valid
        // e.g., navigate("/login");
      }
    };

    checkUserToken();
  }, []);


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {/* {layout === "dashboard" && ( */}
      {layout === "dashboard" &&
        isAdmin === true &&
        pathname !== "/login" &&
        pathname !== "/register" && (
          <Sidenav
            color={sidenavColor}
            brand={brandDark}
            brandName="Secure Assets"
            routes={adminRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
      {layout === "dashboard" &&
        isCustomer === true &&
        pathname !== "/login" &&
        pathname !== "/register" && (
          <Sidenav
            color={sidenavColor}
            brand={brandDark}
            brandName="Secure Assets"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
      {layout === "dashboard" &&
        isAgent === true &&
        pathname !== "/login" &&
        pathname !== "/register" && (
          <Sidenav
            color={sidenavColor}
            brand={brandDark}
            brandName="Secure Assets"
            routes={agentRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
      {/* {layout === "dashboard" && isNewUser === true && (
        <Sidenav
          color={sidenavColor}
          brand={brandDark}
          brandName="Secure Assets"
          routes={newUserroutes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )} */}
      {/* {layout === "vr" && <Configurator />} */}
      {bypass ? (
        <Routes>
          {isAgent && getRoutes(agentRoutes)}
          {isAdmin && getRoutes(adminRoutes)}
          {isCustomer && getRoutes(routes)}
          {/* {isNewUser && getRoutes(newUserroutes)} */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/logout" element={<Logout setIsCustomer="setIsCustomer" />} />
          <Route
            path="/"
            element={
              <Login
                setIsAdmin={setIsAdmin}
                setIsCustomer={setIsCustomer}
                setIsAgent={setIsAgent}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Login
                setIsAdmin={setIsAdmin}
                setIsCustomer={setIsCustomer}
                setIsAgent={setIsAgent}
              />
            }
          />
          <Route path="/register" element={<SignUp />} />
          <Route path="/push/invite" element={<PushInvite />} />
          <Route path="/verifiedemail/:uid/:token" element={<VerifiedEmail />} />
          <Route path="/google" element={<GoogleSignIn />} />
          <Route path="/forgot-password" element={<Reset />} />
          <Route
            path="/forgetpasswordconfirmation/:uid/:token"
            element={<ResetConformPassword />}
          />
          <Route path="/footer" element={<Footers light="true" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
