/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from "react";
import { Table, Badge, Checkbox } from "@mantine/core";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import RequestedAssetModal from "modals/RequestedAssetModal";
import { fetchAssetRequests } from "services/api.admin.service";

export const Selection = () => <Checkbox />;

// eslint-disable-next-line react/prop-types
export const Name = ({ name, email }) => (
  <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
    <MDTypography style={{ fontSize: "14px", fontWeight: "500", color: "#2f2f2f" }}>
      {name}
    </MDTypography>
    <MDTypography style={{ fontSize: "12px", color: "#b3b3b3" }}>{email}</MDTypography>
  </MDBox>
);

// eslint-disable-next-line react/prop-types
export const Address = ({ addressLine1 }) => (
  <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
    <MDTypography style={{ fontSize: "12px", fontWeight: "400", color: "#2e2e2e" }}>
      {addressLine1}
    </MDTypography>
  </MDBox>
);

// eslint-disable-next-line react/prop-types
const AssetType = ({ type }) => {
  return type === "Open Plot" ? (
    <Badge color="teal" size="xs" radius="xs">
      {type}
    </Badge>
  ) : type === "Apartment" ? (
    <Badge color="red" size="xs" radius="xs" variant="outline">
      {type}
    </Badge>
  ) : (
    <Badge color="blue" size="xs" radius="xs" variant="outline">
      {type}
    </Badge>
  );
};

// eslint-disable-next-line react/prop-types
// const ServiceOpted = ({ serviceName, coloring }) => {
//   return (
//     <Badge color={coloring} size="sm" radius="xs" variant="outline">
//       {serviceName}
//     </Badge>
//   );
// };

// eslint-disable-next-line react/prop-types, no-unused-vars
const Status = ({ status }) => {
  return status === true ? (
    <CheckCircleOutlined style={{ color: "green" }} />
  ) : status === false ? (
    <ExclamationCircleOutlined style={{ color: "orange" }} />
  ) : (
    <CloseCircleOutlined style={{ color: "red" }} />
  );
};

// eslint-disable-next-line react/prop-types
const AssetName = ({ name }) => (
  <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
    <MDTypography style={{ fontSize: "12px", fontWeight: "400", color: "#2e2e2e" }}>
      {name}
    </MDTypography>
  </MDBox>
);

function Base() {
  const [opened, setOpened] = useState(false);
  const [data, setData] = useState();
  const [elements, setElements] = useState([]);

  const handleRowClick = (e) => {
    setData(e);
    setOpened((...prev) => !prev[0]);
  };

  useEffect(() => {
    fetchAssetRequests().then((res) => {
      setElements(res);
    });
  }, []);
  const rows = elements.map((element) => (
    <tr
      key={element.asset_id}
      onClick={() => handleRowClick(element)}
      style={{ cursor: "pointer" }}
    >
      <td>
        <Name name={element.customer_name} email={element.customer_email} />
      </td>
      <td>
        <AssetName name={element.asset_name} />
      </td>
      <td>
        <Address addressLine1={element.asset_address} />
      </td>
      <td>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <AssetType type={element.asset_type} />
        </MDBox>
      </td>
      <td>
        <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {element.services?.map(
            (service) =>
              (service.service_name === "No Service Requests" && (
                <Badge color="red" size="xs" radius="xl" variant="outline">
                  NONE
                </Badge>
              )) ||
              (service.service_name !== "No Service Requests" && (
                <MDBox mb={0.25}>
                  <Badge variant="dot" color="violet" size="xs" radius="xs">
                    {service.service_name}
                  </Badge>
                </MDBox>
              ))
          )}
        </MDBox>
      </td>
      <td>
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <Status status={element.asset_status} />
        </MDBox>
      </td>
    </tr>
  ));

  return (
    <MDBox>
      <Table highlightOnHover>
        <thead>
          <tr style={{ backgroundColor: "#f4f8f9" }}>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Customer
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Asset Name
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Address
                </MDTypography>
              </MDBox>
            </th>

            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Asset Type
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Services Opted
                </MDTypography>
              </MDBox>
            </th>
            <th>
              <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#767676" }}>
                  Status
                </MDTypography>
              </MDBox>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {opened && (
        <RequestedAssetModal
          opened={opened}
          setOpened={setOpened}
          handleRowClick={handleRowClick}
          data={data}
          setElements={setElements}
        />
      )}
    </MDBox>
  );
}

export default Base;
