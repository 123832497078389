/** 

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Assets from "layouts/assets";
import AssetCreation from "layouts/assetCreation";
import AssetView from "layouts/assetsView";
// import Test from "layouts/test";
// import TinCan from "layouts/tincan";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import CreateProfile from "layouts/createProfile";
// import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import GoogleLogin from "layouts/authentication/SocialAuthRedirection";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AssetDetails from "layouts/assetDetails";

import Logout from "layouts/logout";
import Billing from "layouts/billing";
import AssetServices from "layouts/test/AssetServices";
// import Configurator from "examples/Configurator";
// import AssetDetailsSection from "modals/RequestedAssetModal/components/AssetDetailsSection";

const routes = [
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Add Asset",
    key: "asset",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/asset",
    component: <AssetCreation />,
  },
  {
    type: "collapse",
    name: "My Assets",
    key: "assets",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/assets",
    component: <AssetView />,
  },
  // {
  //   type: "collapse",
  //   name: "config",
  //   key: "Config",
  //   route: "/config",
  //   component: <Configurator />
  // },
  {
    type: "route",
    name: "Assets",
    key: "Assets",
    route: "/assets/:id",
    component: <Assets />,
  },
  {
    type: "collapse",
    name: "Services",
    key: "services",
    icon: <HomeRepairServiceIcon fontSize="small" />,
    route: "/services",
    component: <AssetServices />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "Billings",
    key: "billings",
    icon: <CreditScoreIcon fontSize="small" />,
    route: "/billings",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
  },

  // {
  //   type: "collapse",
  //   name: "Test",
  //   key: "test",
  //   route: "/test",

  //   component: <Test />,
  // },
  // {
  //   type: "collapse",
  //   name: "TinCan",
  //   key: "tincan",
  //   route: "/tincan",
  //   component: <TinCan />,
  // },
  {
    type: "route",
    name: "AssetDetails",
    key: "details",
    route: "/assets/:id/details",
    component: <AssetDetails />,
  },
  {
    type: "route",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/register",
    component: <SignUp />,
  },
  {
    type: "route",
    name: "Social Auth",
    key: "social-auth",
    route: "/google",
    component: <GoogleLogin />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile/create",
    component: <CreateProfile />,
  },
];

export default routes;
