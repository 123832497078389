import * as React from "react";

import { DataGrid } from "@mui/x-data-grid";

import MDTypography from "components/MDTypography";

import { Box } from "@mui/material";

const columns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "year", headerName: "Year", width: 70 },
  { field: "month", headerName: "Month", width: 70 },
  {
    field: "executiveName",
    headerName: "Executive Name",
    width: 160,
  },
  {
    field: "visitedDate",
    headerName: "Visited Date",
    description: "Last Updated Date",
    width: 160,
  },
  {
    field: "service",
    headerName: "Service",
    width: 160,
  },
  {
    field: "update",
    headerName: "Update",
    width: 140,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
];

const rows = [
  {
    id: 1,
    year: "2022",
    month: "May",
    executiveName: "Kiran Kumar",
    visitedDate: "2022-05-04",
    service: "Plot Monitoring",
    update: "View Updae",
    status: "active",
  },
  {
    id: 2,
    year: "2022",
    month: "April",
    executiveName: "Kiran Kumar",
    visitedDate: "2022-04-04",
    service: "Plot Monitoring",
    update: "view Update ->>",
    status: "active",
  },
];

export default function DataTable() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <MDTypography
        variant="body"
        style={{
          fontSize: "16px",
          fontWeight: "600",
          textAlign: "left",
          padding: 3,
          marginLeft: 4,
        }}
      >
        Service History
      </MDTypography>
      <div style={{ height: 220, width: "100%" }}>
        <DataGrid rows={rows} columns={columns} pageSize={2} rowsPerPageOptions={[5]} />
      </div>
    </Box>
  );
}
