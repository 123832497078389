/* eslint-disable camelcase */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea, Divider } from "@mantine/core";
// import { ListItem, ListItemText } from "@mui/material";
import {
  getAllNotifications,
  fetchCustomerIdAssetIDByNotificationID,
  approveService,
} from "services/api.admin.service";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Typography from "assets/theme/base/typography";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "examples/Timeline/TimelineItem";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples

// Data

function Notifications() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [notifications, setNotifications] = useState([]);
  const [serviceStatus, setServiceStatus] = useState(false);
  useEffect(() => {
    getAllNotifications().then((res) => {
      setNotifications(res);
    });
  }, []);
  console.log(notifications);
  const handleReview = (e) => {
    const id = e.target.value;

    fetchCustomerIdAssetIDByNotificationID(id).then((res) => {
      navigate(`/customer/${res.user_id}/assets/${res.asset_id}/details`);
    });
  };

  const handleServiceAssignment = (e) => {
    const id = e.target.value;
    fetchCustomerIdAssetIDByNotificationID(id).then((res) => {
      const { asset_id, service_id } = res;
      // eslint-disable-next-line no-unused-vars
      approveService(asset_id, service_id).then((response) => {
        setServiceStatus(true);
      });
    });
  };

  const handleApprove = (e) => {
    console.log(e.target.value);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox display="flex">
          <Icon fontSize="inherit">notifications</Icon>

          <MDTypography px={2} variant="h6" gutterBottom>
            Notifications
          </MDTypography>
          <Divider />
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <ScrollArea style={{ height: 325 }} scrollbarSize={2}>
        <MDBox
          px={3}
          style={{
            backgroundColor: "aliceblue",
          }}
        >
          {/* List Items for notification section *|* 3 */}
          {notifications.map((data) => (
            <MDBox display="flex">
              <TimelineItem
                color="primary"
                icon="notifications"
                title={data.title}
                description={data.description}
                lastItem
              />
              {data.notification_type === "service" ? (
                <MDBox display="block" width="100%">
                  <MDBox p={1} display="flex" alignItems="center" justifyContent="flex-end">
                    {serviceStatus ? (
                      <Button variant="contained" color="success" style={{ color: "orange" }}>
                        SERVICE ASSIGNED
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        value={data.id}
                        onClick={handleServiceAssignment}
                        style={{ color: "green" }}
                      >
                        ASSIGN SERVICE
                      </Button>
                    )}
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox display="block" width="100%">
                  <MDBox mr={2} display="flex" alignItems="center" justifyContent="flex-end">
                    <MDBox p={1}>
                      <MDButton value={data.id} variant="standard" onClick={handleReview}>
                        REVIEW
                      </MDButton>
                    </MDBox>
                    <MDBox p={1}>
                      <MDButton
                        value={data.id}
                        color="error"
                        variant="outlined"
                        onClick={handleApprove}
                      >
                        APPROVE
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              )}
            </MDBox>
          ))}
        </MDBox>
      </ScrollArea>
    </Card>
  );
}

export default Notifications;
