/* eslint-disable react/prop-types */
import React from "react";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

import Grid from "@mui/material/Grid";

import avatardefault from "assets/images/avatardefault.png";

function Base({
  firstName,
  lastName,
  email,
  phoneNumber,
  handleNameChange,
  handleLastNameChange,
  handleMobileChange,
}) {
  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={8} spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} spacing={1}>
                <MDBox width="100%" display="flex" justifyContent="center">
                  <MDInput
                    label="First Name"
                    value={firstName}
                    onChange={handleNameChange}
                    sx={{ width: "100%" }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} spacing={1}>
                <MDBox width="100%" display="flex" justifyContent="center">
                  <MDInput
                    label="Last Name"
                    value={lastName}
                    onChange={handleLastNameChange}
                    sx={{ width: "100%" }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} spacing={1}>
                <MDBox width="100%" display="flex" justifyContent="center">
                  <MDInput label="Email" value={email} sx={{ width: "100%" }} disabled />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} spacing={1}>
                <MDBox width="100%" display="flex" justifyContent="center">
                  <MDInput
                    label="Mobile Number"
                    value={phoneNumber}
                    onChange={handleMobileChange}
                    sx={{ width: "100%" }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <MDBox display="flex" flexDirection="row" justifyContent="center">
          <MDAvatar src={avatardefault} alt="profile-image" size="xl" shadow="sm" />
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default Base;
