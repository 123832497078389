/* eslint-disable*/
export const createUser = async (data) => {
  const url = `${process.env.REACT_APP_DOMAIN_NAME}/auth/users/`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(url, options);
  const result = await response.json();
  console.log(result);

  // const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/auth/users/`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(data),
  // })
  //   .then((response) => {
  //     response.json();
  //     console.log(response.json());
  //   })
  //   .then((dt) => {
  //     console.log(dt);
  //   });
  const resultObj = { result, response };
  return resultObj;
};

export const userTokenCheck = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/auth/jwt/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    body: JSON.stringify({
      token: localStorage.getItem("auth_token"),
    }),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
  });
  return result;
};

export const tapUserLite = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/tap/lite`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((dt) => dt);
  return result;
};

export const fetchUserNotifications = () => {
  const url = `${process.env.REACT_APP_DOMAIN_NAME}/v1/user/notifications`;
  const result = fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const activateAccount = (uid, token) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/auth/users/activation/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uid,
      token,
    }),
  })
    .then((response) => response)
    .then((data) => data);
  return result;
};

export const fetchUserDetails = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/auth/userupdate/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const updateUserDetailsSelf = (user) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/auth/userupdate/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(user)
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// create user profile
export const createUserProfile = (data) => {
  const user = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    gender: data.gender,
    personal_id: data.uniqueId,
    contact_mobile: data.phoneNumber,
    company_name: data.companyName,
    address_line_1: data.addressLine1,
    address_line_2: data.addressLine2,
    city: data.city,
    state: data.state,
    landmark: data.landmark,
    zip_code: data.zipCode,
    country: data.country,
    facebook_id: data.facebookId,
    linkdin_id: data.instaId,
    twitter_id: data.twitterId,
  };
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      return null;
    })
    // eslint-disable-next-line no-shadow
    .then((data) => data);
  return result;
};

export const fetchUserProfile = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customer`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// fetch assets from the server
export const fetchAssets = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/asset/customerlistcreate/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetDetails = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/asset/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const createOpenPlotAsset = async (data) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined) {
      formData.append(key, value);
    }
  });

  const response = await fetch("http://localhost:8000/asset/customerlistcreate/", {
    method: "POST",
    headers: {
      Authorization: `token ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (!response.ok) throw new Error("Network response was not ok");

  return response.text();
};

export const register = (data) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/auth/users/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      re_password: data.confirmPassword,
    }),
  })
    // eslint-disable-next-line consistent-return
    .then((response) => {
      if (response.status === 201) {
        return response.json();
      }
    })
    // eslint-disable-next-line no-shadow
    .then((data) => data);
  return result;
};

export const fetchAssetGeneralInfo = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const getInactiveServices = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${id}/services`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

//  passing asset id
export const fetchAssetUdates = (id) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/updates/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetUpdatedImages = (updateId) => {
  const result = fetch(
    `${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/updates/images/${updateId}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// Notifications
export const fetchAllNotifications = () => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/notifications`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// search customers by name
export const searchCustomersByName = (name) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customers/search?name=${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

// search customers by email
export const searchCustomersByEmail = (email) => {
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/customers/search?email=${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const requestToAssiciateServices = (aid, sid) => {
  console.log("sid", sid);
  const result = fetch(`${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${aid}/services/${sid}/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data);
  return result;
};

export const fetchAssetServiceView = (serviceId, assetId) => {
  const result = fetch(
    `${process.env.REACT_APP_DOMAIN_NAME}/v1/asset/${assetId}/services/${serviceId}/view`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data);
  return result;
};
