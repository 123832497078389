/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import CardMedia from "@mui/material/CardMedia";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";
import { GoogleLoginButton } from "react-social-login-buttons";
// import { Spin } from "antd";
// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useMaterialUIController, setBypass } from "context";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import { useGlobalState } from "index";

import { fetchUserDetails } from "services/api.service";

// import loginbg from "assets/images/loginbg.jpg";
import bgprofile from "assets/images/hero-bg1.jpg";
import logo from "assets/images/logo.png";
// style .css
import "./index.css";
import { Loader } from "@mantine/core";

export const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    //   overflow: "hidden",
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
      color: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    },
  },
}));

function Basic({ setIsAdmin, setIsCustomer, setIsAgent }) {
  // const image = bgprofile;
  const [controller, dispatch] = useMaterialUIController();
  const { bypass } = controller;
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loginError, setLoginError] = React.useState(false);
  const [errorMsg, setErrormsg] = React.useState("");

  // const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // useEffect(() => {
  //   if (isAdmin) {
  //     setBypass(true);
  //     // set bypass true and isAdmin True
  //   } else if (isCustomer) {
  //     setBypass(true);
  //     //  set bypass true and isAdmin False
  //   }
  // }, [isAdmin, isCustomer]);

  const hitLoginEndpoint = async () => {
    try {
      setLoading(true);
      const res = await fetchUserDetails();
      const { role } = res;
      console.log(role, res);
      setLoading(false);
      if (role === 'admin') {
        setIsAdmin(true);
        setIsCustomer(false);
        setIsAgent(false);
      } else if (role === 'agent') {
        setIsAgent(true);
        setIsAdmin(false);
        setIsCustomer(false);
      } else {
        setIsCustomer(true);
        setIsAdmin(false);
        setIsAgent(false);
      }
  
      setBypass(dispatch, true);
      localStorage.setItem("user", JSON.stringify(res));
    } catch (error) {
      // Handle errors if needed
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  };
  

  const onGoogleLogin = async () => {
    const response = await fetch(
      "http://localhost:8000/auth/o/google-oauth2/?redirect_uri=http://localhost:3000/google",
      {
        credentials: "include",
        method: "GET",
      }
    );
    const data = await response.json();
    window.open(data.authorization_url, "_self");
  };

  const loginFormSubmitHandler = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_DOMAIN_NAME}/auth/login/`;
      const options = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: email,
          password,
        }),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        // localStorage.setItem("auth_token", data.access);
        // localStorage.setItem("refresh_token", data.refresh);
        setLoginError(false);
        setLoading(false);
        hitLoginEndpoint();
        // if (localStorage.getItem("auth_token") !== null) {
        //   // hitLoginEndpoint();
        // }
      } else {
        setLoginError(true);
        setErrormsg("Invalid email or password");
        setLoading(false);
      }
    } catch (error) {
      setLoginError(true);
      setErrormsg(error);
    }

    // fetch(`http://localhost:8000/auth/jwt/create/`, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     email,
    //     password,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.access) {
    //       localStorage.setItem("auth_token", data.access);
    //       localStorage.setItem("refresh_token", data.refresh);
    //       if (localStorage.getItem("auth_token") !== null) {
    //         hitLoginEndpoint();
    //       }
    //     }
    //   });
  };
  const handleEnterPress = (event) => {
    if (event.charCode === 13) {
      loginFormSubmitHandler();
    }
  };
  // useEffect(() => {
  //   window.location.reload(true);
  // });

  return (
    <nav className="sigin-bg">
      <Card
        sx={{
          background: "#d7d7d7e0",
          marginTop: "3rem",
          width: "20rem",
        }}
      >
        <MDBox
          variant="gradient"
          borderRadius="lg"
          background="null"
          coloredShadow="error"
          mx={2}
          mt={-5}
          p={2}
          textAlign="center"
          sx={{ background: `radial-gradient(black, black)` }}
        >
          <MDBox
            sx={{ width: "53%", height: "60%", alignItems: "center", justifyContent: "center" }}
            component="img"
            variant="rect"
            src={logo}
          />

          {/* <MDBox
            width="10vh"
            height="20vh"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                image &&
                `${linearGradient(
                  rgba(gradients.dark.main, 0.6),
                  rgba(gradients.dark.state, 0.6)
                )}, url(${image})`,
            }}
          /> */}
          {/* <MDTypography variant="h4" fontWeight="medium" color="red" mt={1}>
            SecureAssets
          </MDTypography> */}
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 1 }}> */}
          {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="info">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid> */}

          {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="grey">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid> */}
          {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="error">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid> */}
          {/* </Grid> */}
        </MDBox>

        <MDBox borderRadius={8} pt={3} pb={3} px={3}>
          {loginError && <pre className="error-msg">{errorMsg}</pre>}
          <MDBox component="form" role="form">
            <MDBox mb={2} mt={1}>
              <MDInput
                type="email"
                label="Email"
                textColor="red"
                onChange={handleEmailChange}
                fullWidth
                autoComplete="off"
              />
              {/* <RedditTextField
                label="Email"
                variant="filled"
                id="reddit-input-login"
                type="email"
                autoComplete="off"
                key="login"
                sx={{
                  width: "100%",
                  marginTop: 1,
                  fontSize: "1rem",
                }}
                //   color="secondary"
                onChange={handleEmailChange}
                value={email}
              /> */}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                onKeyPress={handleEnterPress}
                onChange={handlePasswordChange}
                fullWidth
              />
              <p style={{ textAlign: "end", fontSize: "12px" }}>
                <a href="/forgot-password" className="forgot-password">
                  {" "}
                  Forgot Password?
                </a>
              </p>

              {/* <RedditTextField
                label="Password"
                key="pwd"
                id="reddit-input-pwd"
                sx={{ width: "100%" }}
                variant="filled"
                type="password"
                autoComplete="off"
                //   color="secondary"
                onChange={handlePasswordChange}
                value={password}
              /> */}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="bold"
                color="black"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton variant="gradient" color="error" onClick={loginFormSubmitHandler} fullWidth>
                {loading ? <Loader color="white" size="sm" /> : "sign in"}
              </MDButton>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography fontWeight="bold" variant="button" color="black">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="error"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
                <hr className="horizontal-line" />
                <MDTypography
                  component={MuiLink}
                  variant="body1"
                  color="error"
                  style={{ width: "200px", textAlign: "center" }}
                >
                  <GoogleLoginButton onClick={onGoogleLogin} style={{ fontSize: "12px" }} />
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </nav>
  );
}

export default Basic;
