import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { ScrollArea, Loader } from "@mantine/core";

import { Grid, ListItem, Box } from "@mui/material";

import { fetchAssetUdates } from "services/api.service";

import ImageViewComponent from "./ImageViewComponent";

function AssetUpdateComponent() {
  const { id } = useParams();

  const [selectedId, setSelectedId] = useState("");

  const [updateDates, setUpdateDates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchAssetUdates(id).then((res) => {
      setUpdateDates(res);
      if (res.length > 0) {
        setSelectedId(res[0].id);
      }
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   if (updateDates.length > 0) {
  //     setSelectedId(updateDates[0].id);
  //   }
  // }, [updateDates]);

  const handleClick = (itemId) => {
    setSelectedId(itemId);
  };
  return (
    <Grid
      sx={{
        display: "flex",
        width: "100%",
        flexGrow: 1,
      }}
    >
      {loading ? (
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <Loader color="dark" variant="bars" />
        </MDBox>
      ) : (
        updateDates.length === 0 && (
          <MDBox
            sx={{
              width: "20%",
              height: "100%",
              borderRight: "1px solid #e0e0e0",
            }}
          >
            <MDTypography variant="h6" sx={{ textAlign: "center" }}>
              No Updates yet. Checkout the Available services for your asset in details.
            </MDTypography>
          </MDBox>
        )
      )}

      {!loading && updateDates.length > 0 && (
        <Grid item span={10}>
          {/* Services section */}
          <MDBox
            style={{
              backgroundColor: "#f5f5f5",
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 300,
              height: 340,
              marginTop: 2,
              marginRight: 1,
              boxShadow: "0px 0px 10px 0px rgba(0,0,1,0.2)",
            }}
            xs={12}
            md={4}
            lg={4}
          >
            {/* <AssetServices /> */}
            <MDBox sx={{ display: "flex", widht: "100%" }}>
              <MDTypography variant="h6" fontWeight="medium" pt={3} px={2}>
                Updates
              </MDTypography>
            </MDBox>
            <ScrollArea style={{ height: 325 }} scrollbarSize={2}>
              <MDBox
                pt={1}
                px={2}
                sx={{
                  width: "100%",
                }}
                style={{
                  "&:hover": {
                    boxShadow: "0px 0px 5px rgba(0, 0, 0.5, 1)",
                  },
                }}
              >
                {updateDates.map((item) => (
                  <div key={item.id}>
                    <ListItem button>
                      <MDBox
                        sx={{
                          width: "100%",
                          p: 1,
                          pl: 1.5,
                          pr: 1.5,
                          borderBottom: "1px solid #ccc",
                          "&:hover": {
                            boxShadow: "0px 0px 0px rgba(0, 0, 0.5, 1)",
                            cursor: "pointer",
                          },
                          "&:onClick": {
                            color: "red",
                          },
                          "&:active": {
                            color: "red",
                          },
                          "&:focus": {
                            boxShadow: "0px 0px 10px rgba(0, 0, 0.5, 1)",
                          },
                          "&:selected": {
                            backgroundColor: "#ccc",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0.5, 1)",
                          },
                          "&:de-selected": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => handleClick(item.id)}
                      >
                        {item.id === selectedId ? (
                          <MDTypography
                            component="div"
                            sx={{
                              fontSize: "14px",
                              color: "#cc0000",
                            }}
                          >
                            {item.date.split("T")[0]}
                          </MDTypography>
                        ) : (
                          <MDTypography
                            component="div"
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            {item.date.split("T")[0]}
                          </MDTypography>
                        )}
                      </MDBox>
                    </ListItem>
                  </div>
                ))}
              </MDBox>
            </ScrollArea>
          </MDBox>
        </Grid>
      )}
      <Grid item span={20}>
        <Box
          style={{
            backgroundColor: "#dedede",
            // background: "linear-gradient(to bottom, #efd5d5, #f5f5f5)",
          }}
          sx={{
            display: "block",
            height: 340,
            marginLeft: 1,
            marginTop: 2,
            boxShadow: "0px 0px 10px 0px rgba(0,0,1,0.5)",
          }}
          xs={12}
          md={8}
          lg={8}
        >
          {/* <MDTypography variant="h5">ALT Update Images here</MDTypography> */}
          <ImageViewComponent selectedId={selectedId} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default AssetUpdateComponent;
