/* eslint-disable object-shorthand */
import React, { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
// import CircularProgress from "@mui/material/CircularProgress";
// import Checkbox from "@mui/material/Checkbox";

import { Loader } from "@mantine/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components

import { createUser } from "services/api.service";

function Base() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [load, setLoad] = useState(false);


  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleSubmit = () => {
    setLoad(true);
    const data = {
      name,
      email,
      password,
      re_password: rePassword,
    };
    createUser(data).then((res) => {
      if (res) {
        setLoad(false);
        navigate("/push/invite");
      }
    });
  };

  return (
    <MDBox mt={3} display="flex" justifyContent="center" alignItems="center">
      <MDBox display="flex" flexDirection="column" width="70%" component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Name"
            variant="standard"
            value={name}
            onChange={(e) => handleInputChange(e, setName)}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            variant="standard"
            value={password}
            onChange={(e) => handleInputChange(e, setPassword)}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Confirm Password"
            variant="standard"
            value={rePassword}
            onChange={(e) => handleInputChange(e, setRePassword)}
            fullWidth
          />
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDButton variant="gradient" onClick={handleSubmit} color="error" fullWidth>
            {load ? <Loader color="indigo" size="sm" /> : "INVITE"}
          </MDButton>
        </MDBox>
        {/* {isCreated && <MDTypography variant="subtitle1">Customer Created Successfully!</MDTypography>}
        {isError && (
          <MDTypography variant="subtitle1">Something went wrong, please try again!</MDTypography>
        )} */}
      </MDBox>
    </MDBox>
  );
}

export default Base;
