import React from "react";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Base() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12} md={8} lg={8}>
            <h1>1</h1>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <h1>2</h1>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <h1>3</h1>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <h1>4</h1>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Base;
