/* eslint-disable react/prop-types */
import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Base({ data }) {
  console.log(data);
  return (
    <MDBox p={1} borderRadius="md" shadow="md" bgColor="#ebdfed" mt={1} mb={1}>
      <MDTypography style={{ fontSize: "12px", fontWeight: "400", color: "#2e2e2e" }}>
        Name: {data.asset_service?.customer_name}
      </MDTypography>
      <MDTypography style={{ fontSize: "12px", fontWeight: "400", color: "#2e2e2e" }}>
        Email: {data.asset_service?.customer_email}
      </MDTypography>
    </MDBox>
  );
}

export default Base;
