import React from "react";

// import { useParams } from "react-router-dom";

import { CardContent } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import AddBusinessIcon from "@mui/icons-material/AddBusiness";

import { Space, Divider, List, ThemeIcon } from "@mantine/core";

import { IconCircleCheck, IconCircleDashed } from "@tabler/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// import { getInactiveServices, requestToAssiciateServices } from "services/api.service";

function AssetServices() {
  // const { id } = useParams();
  // const [inactiveServices, setInactiveServices] = useState([]);
  // const [activeServices, setActiveServices] = useState([]);
  const activeServices = [
    {
      service: {
        id: 1,
        serviceCategory: null,
        service_type: "Open Plot",
        serviceName: "Plot Monitoring",
        serviceCost: 200,
      },
      assigned_date: "2022-09-02T11:03:41.869090Z",
      last_updated_date: null,
      service_status: true,
      request_status: true,
      approval_status: true,
    },
    {
      service: {
        id: 2,
        serviceCategory: null,
        service_type: "Open Plot",
        serviceName: "Plot Cleaning",
        serviceCost: 300,
      },
      assigned_date: "2022-09-02T11:45:27.256307Z",
      last_updated_date: null,
      service_status: true,
      request_status: true,
      approval_status: true,
    },
    {
      service: {
        id: 3,
        serviceCategory: null,
        service_type: "Open Plot",
        serviceName: "Plot Fencing",
        serviceCost: 400,
      },
      assigned_date: "2022-09-06T06:38:14.886164Z",
      last_updated_date: null,
      service_status: true,
      request_status: true,
      approval_status: true,
    },
  ];

  const inactiveServices = [
    {
      id: 4,
      serviceCategory: null,
      service_type: "Open Plot",
      serviceName: "Plot Maintanance",
      serviceCost: 500,
    },
  ];

  // React.useEffect(() => {
  //   getInactiveServices(id).then((res) => {
  //     setInactiveServices(res.inactive_services);
  //     // setActiveServices(res.active_services);
  //   });
  // }, []);

  // const handleServiceClick = (sid) => {
  //   requestToAssiciateServices(id, sid).then((res) => {
  //     console.log(res);
  //   })

  const handleServiceClick = (sid) => {
    console.log(sid);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={2}
        shadow="lg"
        color="white"
        variant="gradient"
        borderRadius="lg"
        width="30%"
        sx={{
          // height: 500,
          // width: "100%",
          alignItems: "center",
          // boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <CardContent>
          <MDTypography variant="h5" component="div">
            Active Services&nbsp;({activeServices.length})
          </MDTypography>
          {activeServices.map((item) => (
            <MDBox
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
                marginTop: "20px",
                flexGrow: 1,
                backgroundColor: "#edf5f9",
                borderRadius: 4,
                alignItems: "center",
              }}
            >
              <List
                spacing="xs"
                size="sm"
                center
                icon={
                  <ThemeIcon color="teal" size={24} radius="xl">
                    <IconCircleCheck size={16} />
                  </ThemeIcon>
                }
              >
                <List.Item>{item.service?.serviceName}</List.Item>
              </List>
            </MDBox>
          ))}
          <Space h="md" />
          <Divider variant="dashed" />
          <Space h="md" />
          <MDTypography variant="h5" component="div">
            Available Services
          </MDTypography>
          <Space h="md" />
          {inactiveServices.map((item) => (
            <MDBox
              display="flex"
              flexDirection="row"
              shadow="lg"
              borderRadius="lg"
              alignItems="cneter"
              color="white"
              variant="gradient"
              justifyContent="flex-start"
              onClick={() => handleServiceClick(item.id)}
              style={{
                width: "100%",
                marginTop: "20px",
                flexGrow: 1,
                backgroundColor: "#edf5f9",
                cursor: "pointer",
              }}
            >
              <List
                spacing="xs"
                size="sm"
                center
                icon={
                  <ThemeIcon color="teal" size={24} radius="xl">
                    <IconCircleCheck size={16} />
                  </ThemeIcon>
                }
              >
                <List.Item
                  icon={
                    <ThemeIcon color="blue" size={24} radius="xl">
                      <IconCircleDashed size={16} />
                    </ThemeIcon>
                  }
                >
                  {item.serviceName}
                </List.Item>
              </List>
            </MDBox>
          ))}
          {/* <CouroselPlay data={inactiveServices} /> */}
        </CardContent>
      </MDBox>
    </DashboardLayout>
  );
}

export default AssetServices;

// import { List, ThemeIcon } from "@mantine/core";
// import { IconCircleCheck, IconCircleDashed } from "@tabler/icons";

// function Demo() {
//   return (
//     <List
//       spacing="xs"
//       size="sm"
//       center
//       icon={
//         <ThemeIcon color="teal" size={24} radius="xl">
//           <IconCircleCheck size={16} />
//         </ThemeIcon>
//       }
//     >
//       <List.Item>Clone or download repository from GitHub</List.Item>
//       <List.Item>Install dependencies with yarn</List.Item>
//       <List.Item>To start development server run npm start command</List.Item>
//       <List.Item>Run tests to make sure your changes do not break the build</List.Item>
//       <List.Item
//         icon={
//           <ThemeIcon color="blue" size={24} radius="xl">
//             <IconCircleDashed size={16} />
//           </ThemeIcon>
//         }
//       >
//         Submit a pull request once you are done
//       </List.Item>
//     </List>
//   );
// }
