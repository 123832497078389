import React, { useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";

import { UserAddOutlined, UserSwitchOutlined } from "@ant-design/icons";

import { Checkbox } from "@mantine/core";

import CreateCustomer from "./CreateCustomer";
import InviteAgent from "./InviteAgent";

function Base() {
  const [customer, setCustomer] = useState(true);
  const [agent, setAgent] = useState(false);

  const handleCustomer = () => {
    setCustomer((prev) => !prev);
    setAgent(false);
  };

  const handlleAgent = () => {
    setAgent((prev) => !prev);
    setCustomer(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container style={{ marginTop: 3 }}>
        <Grid item xs={4} md={4} lg={4}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MDBox
                width="100%"
                display="flex"
                flexDirection="row"
                shadow={`${customer ? "lg" : ""}`}
                bgColor={`${customer ? "#c9e0fd" : "#FFFFFF"}`}
                border={`${customer ? "" : "1px solid #237dea"}`}
                borderRadius="md"
                p={3}
                mt={2}
                mb={2}
                onClick={handleCustomer}
                style={{
                  cursor: "pointer",
                }}
              >
                <UserAddOutlined
                  style={{
                    fontSize: "50px",
                  }}
                />
                <MDBox display="flex" flexDirection="column" width="100%" ml={2}>
                  <MDTypography
                    style={{
                      fontSize: "18px",
                      fontWeight: "900",
                    }}
                  >
                    Customer
                  </MDTypography>
                  <MDTypography
                    style={{
                      fontSize: "8px",
                      fontWeight: "700",
                    }}
                  >
                    INVITE
                  </MDTypography>
                </MDBox>
                {customer && <Checkbox checked />}
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="row"
                width="100%"
                shadow={`${agent ? "lg" : ""}`}
                bgColor={`${agent ? "#fdc9c9" : "#FFFFFF"}`}
                border={`${agent ? "" : "1px solid #f44336"}`}
                borderRadius="md"
                p={3}
                mt={2}
                mb={2}
                onClick={handlleAgent}
                style={{
                  cursor: "pointer",
                }}
              >
                <UserSwitchOutlined style={{ fontSize: "50px" }} />
                <MDBox display="flex" flexDirection="column" width="100%" ml={2}>
                  <MDTypography style={{ fontSize: "18px", fontWeight: "900" }}>Agent</MDTypography>
                  <MDTypography style={{ fontSize: "8px", fontWeight: "700" }}>INVITE</MDTypography>
                </MDBox>
                {agent && <Checkbox checked color="red" />}
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} md={8} lg={8}>
          {customer && <CreateCustomer />}
          {agent && <InviteAgent />}
          {!customer && !agent && (
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <MDTypography
                style={{
                  fontSize: "18px",
                  fontWeight: "900",
                }}
              >
                Invite a Customer or Agent
              </MDTypography>
            </MDBox>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Base;
