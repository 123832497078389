/* eslint-disable camelcase */
/* eslint-disable no-const-assign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Tabs, Divider, Badge, Button } from "@mantine/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { IconCalendarTime, IconMailForward, IconClipboardX, IconUserCheck } from "@tabler/icons";
import { fetchAssetServiceView } from "services/api.service";
import SelectItem from "./activeItems/selectItem";

function Active({ activeServices, assetId }) {
  const [recurrenceValue, setRecurrenceValue] = useState("NA");
  const [loading, setLoading] = useState(false);

  const deff = (activeServices.length > 0 && activeServices[0].service?.serviceName) || "";
  const sId = (activeServices.length > 0 && activeServices[0].service?.serviceId) || "";
  const [value, setValue] = useState(deff);
  const [sid] = useState(sId);

  // Plot Monitoring service View States
  const [previousUpdate, setPreviousUpdate] = useState("");
  const [nextUpdate, setNextUpdate] = useState("");
  const [recurrencePeriod, setRecurrencePeriod] = useState();
  const [agentName, setAgentName] = useState("");
  const [visitCounter, setVisitCounter] = useState("");
  const [assignedDate, setAssignedDate] = useState("");

  // useEffect to load all the services from activeServices props and set the first one the value state
  // useEffect(() => {
  //   if (activeServices.length > 0) {
  //     setValue(activeServices[0].service?.serviceName);
  //   }
  // }, [activeServices]);

  const handleRecurrenceChangeClick = () => {
    setLoading((prev) => !prev);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading((prev) => !prev);
      }, 3000);
    }
  }, [loading]);

  useEffect(() => {
    fetchAssetServiceView(sId, assetId).then((res) => {
      if (res) {
        if (!res.last_update) {
          setPreviousUpdate("");
        } else {
          const lst_updt = new Date(res.last_update).toDateString();
          setPreviousUpdate(lst_updt);
        }
        // convert next update to date string
        if (!res.next_update) {
          setNextUpdate("");
        } else {
          const nxt_updt = new Date(res.next_update).toDateString();
          setNextUpdate(nxt_updt);
        }
        const assigned_date = new Date(res.assigned_date).toDateString();
        setAssignedDate(assigned_date);
        setRecurrencePeriod(res.recurrence_period);
        setAgentName(res.agent_name);
        setVisitCounter(res.visit_count);
      }
    });
  }, [sid]);

  const handleServiceClickDisplay = (serviceName, serviceId, assId) => {
    setValue(serviceName);
    // TODO: control res
    fetchAssetServiceView(serviceId, assId).then((res) => {
      console.log(res);
    });
  };

  return (
    <MDBox>
      <Tabs defaultValue={value} orientation="vertical">
        <Tabs.List sx={{ display: "flex", flexDirection: "column" }}>
          {/* Check for active Service length > 0 */}
          {sid === "" && (
            <MDTypography style={{ fontSize: "14px", fontWeight: "500" }}>
              No Active Services
            </MDTypography>
          )}
          {sid !== "" &&
            activeServices.map((ser) => (
              <Tabs.Tab
                value={ser.service?.serviceName}
                key={ser.service?.serviceId}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  height: "1rem",
                }}
                onClick={() =>
                  handleServiceClickDisplay(
                    ser.service?.serviceName,
                    ser.service?.serviceId,
                    assetId
                  )
                }
              >
                <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                  {ser.service?.serviceName}
                </MDTypography>
              </Tabs.Tab>
            ))}
        </Tabs.List>

        <Tabs.Panel value="Plot Monitoring" sx={{ marginLeft: "1rem" }}>
          <MDBox
            mb={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <MDBox display="flex">
              {previousUpdate !== "" && (
                <>
                  <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                    Previous Update:&nbsp;
                  </MDTypography>
                  <Badge variant="gradient" gradient={{ from: "#ed6ea0", to: "#ec8c69", deg: 35 }}>
                    {previousUpdate}
                  </Badge>
                </>
              )}
              {previousUpdate === "" && (
                <>
                  <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                    Assigned Date:&nbsp;
                  </MDTypography>
                  <Badge variant="gradient" gradient={{ from: "#ed6ea0", to: "#ec8c69", deg: 35 }}>
                    {assignedDate}
                  </Badge>
                </>
              )}
            </MDBox>
            <MDBox display="flex">
              {nextUpdate !== "" && (
                <>
                  <MDTypography
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "2e2e2e",
                      alignItems: "center",
                    }}
                  >
                    Next Update:&nbsp;
                  </MDTypography>
                  <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
                    {nextUpdate}
                  </Badge>
                </>
              )}
            </MDBox>
          </MDBox>
          <Divider mb={2} />
          <MDBox display="flex" flexDirection="row" mt={2} mb={2}>
            <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
              Recurrence Period:&nbsp;
            </MDTypography>
            <Badge radius="xs">
              {recurrencePeriod === "NA" && (
                <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                  Not Applicable
                </MDTypography>
              )}
              {recurrencePeriod === "2W" && (
                <MDTypography style={{ fontSize: "12px", fontWeight: "500" }}>2 weeks</MDTypography>
              )}
              {recurrencePeriod === "1M" && (
                <MDTypography style={{ fontSize: "12px", fontWeight: "500" }}>1 Month</MDTypography>
              )}
              {recurrencePeriod === "3M" && (
                <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                  3 Months
                </MDTypography>
              )}
              {recurrencePeriod === "6M" && (
                <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                  6 Months
                </MDTypography>
              )}
              {recurrencePeriod === "1Y" && (
                <MDTypography style={{ fontSize: "14px", fontWeight: "500", color: "2e2e2e" }}>
                  1 Year
                </MDTypography>
              )}
            </Badge>
          </MDBox>
          <MDBox mb={2} display="flex" flexDirection="row" justifyContent="space-around">
            <MDBox display="flex">
              <SelectItem
                recurrenceValue={recurrenceValue}
                setRecurrenceValue={setRecurrenceValue}
                recurrencePeriod={recurrencePeriod}
              />
            </MDBox>
            <MDBox display="flex" alignItems="flex-end">
              {loading && (
                <Button color="red" leftIcon={<IconCalendarTime size={16} />} loading>
                  <MDTypography style={{ fontSize: "12px", fontWeight: "350", color: "#ffffff" }}>
                    Request for change of Recurrence Period
                  </MDTypography>
                </Button>
              )}
              {!loading && (
                <Button
                  color="red"
                  leftIcon={<IconCalendarTime size={16} />}
                  onClick={handleRecurrenceChangeClick}
                >
                  <MDTypography style={{ fontSize: "12px", fontWeight: "350", color: "#ffffff" }}>
                    Request for change of Recurrence Period
                  </MDTypography>
                </Button>
              )}
            </MDBox>
          </MDBox>
          <Divider mb={2} />
          <MDBox mb={1} mt={1} display="flex" flexDirection="row" justifyContent="space-around">
            <MDBox display="flex" alignItems="center">
              <IconUserCheck size={16} strokeWidth={3} color="#194d1b" />
              &nbsp;
              <MDTypography style={{ fontSize: "14px", fontWeight: "500", color: "#2e2e2e" }}>
                {agentName}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <MDTypography style={{ fontSize: "12px", color: "#2e2e2e" }}>
                Visits:&nbsp;
              </MDTypography>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderBottom="1px solid"
              >
                <MDTypography
                  style={{
                    fontSize: "10px",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    borderRadius: "4px",
                    color: "white",
                    backgroundColor: "black",
                  }}
                >
                  {visitCounter}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <Button
                variant="outline"
                leftIcon={<IconMailForward size={16} />}
                radius="xl"
                size="xs"
              >
                Request Service History
              </Button>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox mt={1} display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="subtle"
              leftIcon={<IconClipboardX size={16} />}
              color="red"
              radius="xl"
              size="xs"
            >
              Request Service Denial
            </Button>
          </MDBox>
        </Tabs.Panel>
        <Tabs.Panel value="Plot Cleaning" sx={{ marginLeft: "1rem" }}>
          Messages tab content
        </Tabs.Panel>
        <Tabs.Panel value="Fencing" sx={{ marginLeft: "1rem" }}>
          Settings tab content
        </Tabs.Panel>
      </Tabs>
    </MDBox>
  );
}

export default Active;
