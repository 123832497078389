/* eslint-disable react/prop-types */
import React from "react";

import MDInput from "components/MDInput";

import Grid from "@mui/material/Grid";

function Base({
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  zipCode,
  handleAddress1,
  handleAddress2,
  handleCityChange,
  handleStateChange,
  handleCountryChange,
  handleZipCodeChange,
}) {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <Grid container spacing={2} sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
        <Grid item xs={12} md={6} lg={6}>
          <MDInput
            label="Address Line 1"
            value={addressLine1}
            onChange={handleAddress1}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDInput
            label="Address Line 2"
            value={addressLine2}
            onChange={handleAddress2}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={12} md={6} lg={6}>
          <MDInput label="City" value={city} onChange={handleCityChange} sx={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDInput
            label="State"
            value={state}
            onChange={handleStateChange}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={12} md={6} lg={6}>
          <MDInput
            label="Country"
            value={country}
            onChange={handleCountryChange}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDInput
            label="Zip Code"
            value={zipCode}
            onChange={handleZipCodeChange}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Base;
