import React from "react";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import PersonIcon from "@mui/icons-material/Person";
// import LinearProgress from "@mui/material/LinearProgress";
import { Progress } from "@mantine/core";

function Base() {
  return (
    <MDBox
      display="flex"
      flexDirection="row"
      width="fit-content"
      height="fit-content"
      shadow="lg"
      bgColor="linear-gradient(to top right, #d1d6dc, #e6f4f8, #eceff0)"
      borderRadius="xl"
    >
      <MDBox display="inline-flex" bgColor="#fff" p={1} height="fit-content" borderRadius="xxl">
        <PersonIcon fontSize="small" />
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center" ml={2}>
        <Progress
          sx={{
            width: "150px",
          }}
          value={30}
          animate
        />
      </MDBox>
    </MDBox>
  );
}

export default Base;
