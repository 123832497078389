/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Chip } from "@mantine/core";
// import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { fetchUserProfile } from "services/api.admin.service";
import avatardefault from "assets/images/avatardefault.png";
import OpenPlotForm from "layouts/assetCreation/Forms/OpenPlotForm";

function AssetCreation() {
  const { id } = useParams();
  const [user, setUser] = useState([]);
  //   const assetType = [
  //     {
  //       value: "Open Plot",
  //       label: "Open Plot",
  //     },
  //     {
  //       value: "Apartment",
  //       label: "Apartment",
  //     },
  //     {
  //       value: "House",
  //       label: "House",
  //     },
  //   ];

  useEffect(() => {
    fetchUserProfile(id)
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // eslint-disable-next-line react/no-unstable-nested-components
  const Author = ({ image, custName, custEmail, custMobile }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={custName} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {custName}
        </MDTypography>
        <MDTypography variant="caption">{custEmail}</MDTypography>
        <MDBox mt={1}>
          <MDTypography display="block" variant="caption">
            {custMobile}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );

  const [selectedAssetType, setSelectedAssetType] = useState("Open Plot");

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        variant="gradient"
        display="flex"
        coloredShadow="info"
        borderRadius="lg"
        boxShadow="xl"
        sx={{ background: "aliceblue" }}
        mt={3}
        p={2}
      >
        <Author
          image={avatardefault}
          custName={user.data?.first_name}
          custEmail={user.email}
          custMobile={user.data?.contact_mobile}
        />
      </MDBox>

      <MDBox
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        boxShadow="xl"
        sx={{ background: "aliceblue" }}
        mt={3}
        p={2}
      >
        <Chip.Group multiple={false} value={selectedAssetType} onChange={setSelectedAssetType}>
          <Chip variant="filled" color="teal" value="Open Plot">
            Open Plot
          </Chip>
          <Chip variant="filled" color="teal" value="Apartment">
            Apartment
          </Chip>
          <Chip variant="filled" color="teal" value="House">
            House
          </Chip>
        </Chip.Group>
        <OpenPlotForm assetType={selectedAssetType} userName={user.data?.first_name} />
        
      </MDBox>
    </DashboardLayout>
  );
}

export default AssetCreation;
