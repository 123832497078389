import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import { Box } from "@mui/material";

import { fetchAssetUpdatedImages } from "services/api.service";

// eslint-disable-next-line import/no-unresolved
import "slick-carousel/slick/slick.css";
// eslint-disable-next-line import/no-unresolved
import "slick-carousel/slick/slick-theme.css";

// eslint-disable-next-line react/prop-types
function SimpleSlider({ selectedId }) {
  const [images, setImages] = useState([]);
  // const imgs = [];

  const base64Prefix = "data:image/png;base64,";
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    if (selectedId !== "") {
      fetchAssetUpdatedImages(selectedId).then((res) => {
        // console.log(res);
        setImages(res);
      });
    }
  }, [selectedId]);

  // useEffect(() => {
  //   images.map((image) => console.log(image));
  // }, [images]);

  return (
    <Box
      sx={{
        display: "block",
        height: 280,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Box
        style={{
          backgroundColor: "#f5f5f5",
        }}
        sx={{
          display: "flex",
          width: 700,
          height: 340,
          marginLeft: 1,
          marginTop: 2,
          borderRadius: 3,
          boxShadow: "0px 0px 10px 0px rgba(0,0,1,0.2)",
        }}
        xs={12}
        md={8}
        lg={8}
      > */}
      <Slider
        {...settings}
        style={{
          display: "inline-block",
          justifyContent: "flex-end",
          width: 600,
        }}
      >
        {images.map((image, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <img
              src={`${base64Prefix}${image.image}`}
              alt="random"
              style={{
                display: "inline-block",
                width: 600,
                height: 340,
              }}
            />
          </div>
        ))}
      </Slider>
      {/* </Box> */}
    </Box>
  );
}

export default SimpleSlider;
