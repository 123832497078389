/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useParams } from "react-router-dom";

// import { ExclamationCircleOutlined } from "@ant-design/icons";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Space, Divider, List, ThemeIcon } from "@mantine/core";

import { IconCircleCheck, IconCircleDashed, IconAlertCircle } from "@tabler/icons";

// import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { getInactiveServices, requestToAssiciateServices } from "services/api.service";
import ServiceModal from "../ServiceModal";

function AssetServices() {
  const { id } = useParams();
  const [inactiveServices, setInactiveServices] = useState([]);
  const [activeServices, setActiveServices] = useState([]);
  const [requestedServices, setRequestedServices] = useState([]);

  const [openServiceModal, setOpenServiceModal] = useState(false);

  const [reBreathe, setReBreathe] = useState(false);

  React.useEffect(() => {
    getInactiveServices(id).then((res) => {
      setInactiveServices(res.inactive_services);
      setActiveServices(res.active_services);
      setRequestedServices(res.requested_services);
    });
  }, [reBreathe]);

  console.log(requestedServices);

  const handleServiceClick = (sid) => {
    requestToAssiciateServices(id, sid).then((res) => {
      console.log(res);
    });
  };

  const handleServiceModalClick = () => {
    setOpenServiceModal((prev) => !prev);
  };

  return (
    <MDBox
      // shadow="lg"
      color="white"
      variant="gradient"
      display="flex"
      width="100%"
      height="100%"
      sx={{
        "&:hover": {
          boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
          cursor: "pointer",
          // animate from shadow to normal and normal to shadow continuously in a loop
          transition: "box-shadow 1s ease-in-out ",
          animation: "transition 0.5s infinite alternate",
        },
        // on click animate like a push botton
        "&:active": {
          boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
          transform: "scale(0.99)",
          transition: "transform 0.1s ease-in-out",
        },
      }}
      onClick={handleServiceModalClick}
    >
      <MDBox display="flex" flexDirection="column" width="100%" m={2}>
        <MDBox display="flex" flexDirection="row">
          <MDTypography
            variant="body2"
            component="div"
            style={{ fontSize: "14px", fontWeight: "500", borderBottom: "1px solid" }}
          >
            Active Services&nbsp;
          </MDTypography>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderBottom="1px solid"
          >
            <MDTypography
              style={{
                fontSize: "10px",
                paddingLeft: "4px",
                paddingRight: "4px",
                borderRadius: "4px",
                color: "white",
                backgroundColor: "black",
              }}
            >
              {activeServices.length}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Space h="md" />
        {activeServices.map((item) => (
          <MDBox
            mb={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%",
              flexGrow: 1,
              backgroundColor: "#edf5f9",
              borderRadius: 4,
              alignItems: "center",
            }}
          >
            <List
              spacing="xs"
              size="sm"
              center
              style={{ width: "100%" }}
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <IconCircleCheck size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>{item.service?.serviceName}</List.Item>
            </List>
          </MDBox>
        ))}
        <Space h="md" />
        <Divider variant="dashed" />
        <Space h="md" />
        <MDTypography
          variant="body"
          component="div"
          style={{ fontSize: "14px", fontWeight: "500" }}
        >
          Available Services
        </MDTypography>
        <Space h="sm" />
        {requestedServices.map((item) => (
          <MDBox
            display="flex"
            flexDirection="row"
            shadow="lg"
            borderRadius="lg"
            alignItems="center"
            color="white"
            variant="gradient"
            justifyContent="flex-start"
            mb={2}
            width="100%"
            style={{
              width: "100%",
              flexGrow: 1,
              backgroundColor: "#edf5f9",
              cursor: "pointer",
            }}
          >
            <List spacing="xs" size="sm" center>
              <List.Item
                icon={
                  <ThemeIcon color="orange" size={24} radius="xl">
                    <IconAlertCircle size={16} />
                  </ThemeIcon>
                }
              >
                <MDTypography style={{ fontSize: "14px" }}>
                  {item.service?.serviceName}
                </MDTypography>
                {/* {item.service?.serviceName} */}
              </List.Item>
            </List>
          </MDBox>
        ))}
        {inactiveServices.map((item) => (
          <MDBox
            display="flex"
            flexDirection="row"
            shadow="lg"
            borderRadius="lg"
            alignItems="center"
            color="white"
            variant="gradient"
            justifyContent="flex-start"
            onClick={() => handleServiceClick(item.id)}
            mb={2}
            style={{
              width: "100%",
              flexGrow: 1,
              backgroundColor: "#edf5f9",
              cursor: "pointer",
            }}
          >
            <List
              spacing="xs"
              size="sm"
              center
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <IconCircleCheck size={16} />
                </ThemeIcon>
              }
            >
              <List.Item
                icon={
                  <ThemeIcon color="blue" size={24} radius="xl">
                    <IconCircleDashed size={16} />
                  </ThemeIcon>
                }
              >
                {item.serviceName}
              </List.Item>
            </List>
          </MDBox>
        ))}
        {/* <CouroselPlay data={inactiveServices} /> */}
      </MDBox>
      {openServiceModal && (
        <ServiceModal
          opened={openServiceModal}
          setOpened={setOpenServiceModal}
          activeServices={activeServices}
          inactiveServices={inactiveServices}
          requestedServices={requestedServices}
          assetId={id}
          setInactiveServices={setInactiveServices}
          setReBreathe={setReBreathe}
        />
      )}
    </MDBox>
  );
}

export default AssetServices;
