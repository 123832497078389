/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Divider } from "@mantine/core";
import UploadDragTypeDocuments from "uiComponents/MultiDocsDropzone";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Card, Grid, Icon, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { showNotification, updateNotification } from "@mantine/notifications";
import { createOpenPlotAsset } from "services/api.service";
import AvatarUpload from "uiComponents/AvatarUpload";
import MDButton from "components/MDButton";
// import MDButton from "components/MDButton";

function OpenPlotForm({ userName }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notify, setNotify] = useState(false);
  const [update, setUpdate] = useState(false);

  const [formData, setFormData] = useState({
    asset_name: "",
    asset_type: "",
    address: "",
    city: "",
    state: "",
    pin_code: "",
    country: "",
  });

  const [otherData, setOtherData] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAdditionalData = (e) => {
    const { name, value } = e.target;
    setOtherData({
      ...otherData,
        [name]: value
    })
  }

  const handleImageUpload = (image) => {
    setFormData({
      ...formData,
      selectedImage: image,
    });
  };

  const handleAssetFormSubmit = (event) => {

    event.preventDefault();
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      setNotify(true);
    }
    const data = {
      ...formData,
      asset_data: JSON.stringify(otherData)
    }
    // Remove any properties with undefined values
    const cleanedFormData = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value !== undefined)
    );

    createOpenPlotAsset(cleanedFormData).then(() => {
      setNotify(!notify);
      setSuccess(!success);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (loading && !update) {
      showNotification({
        id: "load-data",
        loading: true,
        title: "Submitting..",
        message: "Open Plot Asset...",
        autoClose: false,
        disallowClose: true,
      });
      setUpdate(!update);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && update) {
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Data sent for verification",
        message: `Open Plot Asset for ${userName} was submitted successfully`,
        autoClose: 2000,
      });
      setNotify(!notify);
      setUpdate(!update);
    }
  }, [update, loading]);

  useEffect(() => {
    if (success) {
      setFormData({
        ...formData,
        name: "",
        location_name: "",
        land_mark: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip_code: "",
        size_in_yards: "",
        site_facing: "",
        survey_number: "",
        registration_number: "",
        sub_registrar_office: "",
        venture: "",
        north: "",
        south: "",
        east: "",
        west: "",
      });
    }
  }, [success]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "row",
      }}
    >
      <Grid item xs={12} md={8} lg={8}>
        <Divider size="xs" my="lg" labelPosition="middle" label="Asset Details" />
        <MDBox display="flex" width="100%" alignItems="center">
          <MDBox p={1} width="100%">
            <Select
              variant="outlined"
              id="asset_type"
              label="Asset Type"
              fullWidth
              xs={12}
              sx={{
                height: "45px",
              }}
              md={12}
              lg={12}
              name="asset_type"
              value={formData.asset_type}
              onChange={handleInputChange}
            >
              <MenuItem value=" ">Select Asset Type</MenuItem>
              <MenuItem value="plot">Open Plot</MenuItem>
              <MenuItem value="apartment">Apartment</MenuItem>
              <MenuItem value="house">House</MenuItem>
            </Select>
          </MDBox>
          <MDBox p={1} width="100%">
            <MDInput
              label="Property Name"
              textColor="secondary"
              value={formData.asset_name}
              fullWidth
              xs={6}
              name="asset_name"
              autoComplete="off"
              required
              onChange={handleInputChange}
            />
          </MDBox>
        </MDBox>
        <Divider size="xs" my="lg" labelPosition="middle" label="Address Details" />
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "center" }}>
          <MDBox display="flex" alignItems="center" flexDirection="row">
            <MDBox p={1} width="100%">
              <MDInput
                label="Address Line 1"
                value={formData.address}
                autoComplete="off"
                fullWidth
                xs={12}
                md={12}
                lg={12}
                name="address"
                onChange={handleInputChange}
              />
            </MDBox>
         
            <MDBox p={1} width="100%">
              <MDInput
                label="City"
                value={formData.city}
                xs={12}
                md={12}
                lg={12}
                name="city"
                autoComplete="off"
                onChange={handleInputChange}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDBox p={1} width="100%">
              <MDInput
                label="District / County"
                //   variant="filled"
                // value={county}
                xs={12}
                md={6}
                lg={6}
                //   InputProps={{ style: { height: 40 }, disableUnderline: true }}
                autoComplete="off"
                fullWidth
                // onChange={(e) => setCounty(e.target.value)}
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                label="State"
                value={formData.state}
                xs={12}
                md={6}
                lg={6}
                name="state"
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                label="Zip Code"
                value={formData.pin_code}
                xs={12}
                md={6}
                lg={6}
                name="pin_code"
                autoComplete="off"
                onChange={handleInputChange}
                fullWidth
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                label="Country"
                value={formData.country}
                xs={12}
                md={6}
                lg={6}
                name="country"
                //   InputProps={{ style: { height: 40 }, disableUnderline: true }}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
              />
            </MDBox>
          </MDBox>
          {/* <MDBox p={1}>
            <MDInput
              label="Location / Area"
              //   variant="filled"
              value={location_name}
              xs={12}
              md={8}
              lg={8}
              //   InputProps={{ style: { height: 40 }, disableUnderline: true }}
              autoComplete="off"
              fullWidth
              onChange={handleLocationName}
            />
          </MDBox> */}
          <Divider size="xs" my="lg" labelPosition="middle" label="Site Details" />
          <Grid item xs={12} md={12} lg={12}>
            <MDBox
              display="flex"
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox p={1} width="100%">
                <MDInput
                  label="Site Facing"
                  variant="outlined"
                  value={otherData.site_facing}
                  onChange={handleAdditionalData}
                  fullWidth
                  name="site_facing"
                />
              </MDBox>
              <MDBox p={1} width="100%">
                <MDInput
                  label="Size In Yards"
                  variant="outlined"
                  value={otherData.size_in_yards}
                  onChange={handleAdditionalData}
                  fullWidth
                  name="size_in_yards"
                />
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox p={1} width="100%">
                <MDInput
                  label="Survey Number"
                  variant="outlined"
                  value={otherData.survey_number}
                  onChange={handleAdditionalData}
                  fullWidth
                  name="survey_number"
                />
              </MDBox>
              <MDBox p={1} width="100%">
                <MDInput
                  label="Registraion Number"
                  variant="outlined"
                  value={otherData.registration_number}
                  onChange={handleAdditionalData}
                  fullWidth
                  name="registration_number"
                />
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox p={1} width="100%">
                <MDInput
                  label="Sub Registrar Office"
                  variant="outlined"
                  value={otherData.sub_registrar_office}
                  onChange={handleAdditionalData}
                  fullWidth
                  name="sub_registrar_office"
                />
              </MDBox>
              <MDBox p={1} width="100%">
                <MDInput
                  label="Venture"
                  variant="outlined"
                  value={otherData.venture}
                  onChange={handleAdditionalData}
                  fullWidth
                  name="venture"
                />
              </MDBox>
            </MDBox>
          </Grid>
          <Divider size="xs" my="lg" labelPosition="middle" label="Dimensions" />
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <MDBox p={1} width="100%">
              <MDInput
                md={12}
                fullWidth
                label="North"
                variant="outlined"
                name="north"
                value={otherData.north}
                onChange={handleAdditionalData}
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                md={12}
                fullWidth
                label="South"
                variant="outlined"
                name="south"
                value={otherData.south}
                onChange={handleAdditionalData}
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                md={12}
                fullWidth
                label="East"
                name="east"
                variant="outlined"
                value={otherData.east}
                onChange={handleAdditionalData}
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                md={12}
                fullWidth
                label="West"
                name="west"
                variant="outlined"
                value={otherData.west}
                onChange={handleAdditionalData}
              />
            </MDBox>
          </MDBox>
          <Divider size="xs" my="lg" labelPosition="middle" label="Geo-Location" />
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDBox p={1} width="100%">
              <MDInput
                label="Latitude"
                md={6}
                fullWidth
                name="latitude"
                variant="outlined"
                value={otherData.latitude}
                onChange={handleAdditionalData}
              />
            </MDBox>
            <MDBox p={1} width="100%">
              <MDInput
                label="Longitude"
                name="longitude"
                variant="outlined"
                md={6}
                fullWidth
                value={otherData.longitude}
                onChange={handleAdditionalData}
              />
            </MDBox>
          </MDBox>

          <MDButton onClick={handleAssetFormSubmit} variant="gradient" color="dark">
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;Create
          </MDButton>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ display: "block" }}>
        {/* Grid container */}
        <Grid container xs={12} md={12} lg={12} sx={{ height: "100%", flexDirection: "row" }}>
          {/* Divider /Asset primary Image */}

          {/* Grid item 2 */}
          {/* Grid item 1 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              height: "50%",
            }}
          >
            {/* Box for asset primary image and docs upload */}
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              py={2}
            >
              {/* Asset primary image upload */}
              <Divider
                sx={{ position: "relative" }}
                size="xs"
                labelPosition="left"
                label="Property Image"
              />
              <MDBox px={3} py={1} xs={12} md={12} lg={12}>
                <AvatarUpload
                  selectedImage={formData.selectedImage}
                  setSelectedImage={handleImageUpload}
                />
              </MDBox>
              {/* Logic for docs upload type://comp */}
              <Divider labelPosition="left" label="Property Documents" py={5} size="xs" />
              <Card
                sx={{
                  ":hover": {
                    boxShadow: 20,
                    cursor: "pointer",
                  },
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  borderColor: "#ddd",
                  margin: "0 20px 0  20px",
                }}
                xs={12}
                md={12}
                lg={12}
              >
                <UploadDragTypeDocuments />
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

OpenPlotForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  userName: PropTypes.string
};

export default OpenPlotForm;
