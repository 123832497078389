import React, { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { Link } from "react-router-dom";

import { Card, CardContent, CardMedia, Box } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import { fetchAssetDetails } from "services/api.service";

// eslint-disable-next-line react/prop-types

// cid: customer id and id:: asset id [PROPS]
// eslint-disable-next-line react/prop-types
function AssetInfoCard({ id }) {
  const [assetDetails, setAssetDetails] = useState({});

  useEffect(() => {
    fetchAssetDetails(id).then((res) => {
      setAssetDetails(res);
    });
  }, [id]);

  return (
    <Card
      sx={{ display: "flex", height: 100, borderRadius: 4, marginTop: 1 }}
      xs={12}
      md={10}
      lg={10}
    >
      <MDBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <CardMedia
          component="img"
          sx={{ width: 300, height: 100, borderRadius: 4 }}
          image="https://source.unsplash.com/random"
          alt="Asset Image"
        />
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CardContent sx={{ width: 555, textAlign: "left" }}>
            <div
              sx={{
                flexDirection: "colomn",
                alignItems: "flex-start",
              }}
            >
              <MDTypography variant="h5" component="div">
                {assetDetails.name}
              </MDTypography>
              <MDTypography variant="subtitle1" color="text.secondary" component="p">
                {assetDetails.address?.location_name}
              </MDTypography>
            </div>
          </CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: "8px",
            }}
          >
            <Link to="details">
              <MDButton
                variant="outlined"
                color="error"
                style={{
                  width: "159px",
                }}
                endIcon={<SendIcon />}
              >
                View Details
              </MDButton>
            </Link>
          </div>
        </Box>
      </MDBox>
    </Card>
  );
}

export default AssetInfoCard;
