/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { Grid, Badge, Skeleton } from "@mantine/core";
import { Card, CardMedia, CardContent } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import GeneralInfo from "layouts/assetDetails/GeneralInfo";
import AssetServices from "adminLayouts/assetDetails/AssetServices";
import DataTable from "layouts/assetDetails/DataTable";

import {
  fetchAssetDetails,
  fetchUserProfile,
  fetchAssetStatus,
  approveAsset,
  declineAsset,
} from "services/api.admin.service";

import avatardefault from "assets/images/avatardefault.png";
import MDButton from "components/MDButton";
import bg_sign_in_basic from "assets/images/bg-sign-in-basic.jpeg";

function AssetDetails() {
  const { cid, id } = useParams();
  const [assetDetails, setAssetDetails] = useState({});

  const [status, setStatus] = useState(null);
  const [statusLoad, setStatusLoad] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const base64Prefix = "data:image/png;base64,";
  useEffect(() => {
    setStatusLoad(true);
    fetchAssetStatus(id)
      .then((res) => {
        setStatus(!res.approval_pending);
        setStatusLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    fetchAssetDetails(id).then((res) => {
      setAssetDetails(res);
      setLoading(false);
    });
  }, [id]);

  const src =
    assetDetails.primary_image !== "NONE"
      ? base64Prefix + assetDetails.primary_image
      : bg_sign_in_basic;

  useEffect(() => {
    fetchUserProfile(cid)
      .then((res) => {
        setUserDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cid]);

  const handleAssetApprove = () => {
    setStatusLoad(true);
    approveAsset(id).then((res) => {
      console.log(res);
      if (res.response === "Asset Approved") {
        fetchAssetStatus(id).then((rip) => {
          setStatus(!rip.approval_pending);
          setStatusLoad(false);
        });
      }
    });
  };

  const handleDecline = () => {
    setStatusLoad(true);
    declineAsset(id).then((res) => {
      if (res.response === "Asset Declined") {
        fetchAssetStatus(id).then((rip) => {
          setStatus(!rip.approval_pending);
          setStatusLoad(false);
        });
      }
    });
  };

  // eslint-disable-next-line no-unused-vars, react/function-component-definition, react/no-unstable-nested-components, react/prop-types
  const Author = ({ image, custName, custEmail, custMobile }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={custName} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {custName}
        </MDTypography>
        <MDTypography variant="caption">{custEmail}</MDTypography>
        <MDBox mt={1}>
          <MDTypography display="block" variant="caption">
            {custMobile}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        variant="gradient"
        display="flex"
        coloredShadow="info"
        borderRadius="lg"
        boxShadow="xl"
        p={2}
      >
        <Author
          image={avatardefault}
          custName={userDetails.data?.first_name}
          custEmail={userDetails.email}
          custMobile={userDetails.data?.contact_mobile}
        />
      </MDBox>
      <MDBox color="white" style={{ height: "100%" }} mt={2}>
        <Grid columns={30}>
          <Grid.Col span={20}>
            <Card
              sx={{
                display: "flex",
                height: 165,
                borderRadius: 2,
                marginTop: 1,
              }}
              xs={12}
              md={10}
              lg={10}
            >
              <MDBox color="white" display="flex" flexDirection="row">
                {loading ? (
                  <MDBox p={1}>
                    <Skeleton width={240} height={120} />
                  </MDBox>
                ) : (
                  <CardMedia
                    component="img"
                    sx={{ width: 300, height: 120, borderRadius: 4 }}
                    image={src}
                  />
                )}
                <MDBox
                  color="white"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <CardContent sx={{ width: 685, textAlign: "left" }}>
                    <MDTypography variant="body2" component="div">
                      {assetDetails.name}
                    </MDTypography>
                    <MDTypography variant="body2" component="p">
                      {assetDetails.address?.location_name}
                    </MDTypography>
                    {statusLoad && <Skeleton width={100} height={20} />}
                    {!statusLoad && status && (
                      <MDBox display="flex" alignItems="center">
                        <MDBox p={1}>
                          <Badge
                            variant="gradient"
                            gradient={{ from: "teal", to: "lime", deg: 105 }}
                          >
                            Approved
                          </Badge>
                        </MDBox>
                        <MDBox>
                          <MDButton
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={handleDecline}
                          >
                            Decline
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    )}
                    {!statusLoad && !status && (
                      <MDBox display="flex" alignItems="center">
                        <MDBox p={1}>
                          <Badge
                            variant="gradient"
                            gradient={{ from: "#ed6ea0", to: "#ec8c69", deg: 35 }}
                          >
                            Approval Pending
                          </Badge>
                        </MDBox>
                        <MDBox p={1}>
                          <MDButton variant="outlined" color="error" onClick={handleAssetApprove}>
                            Approve
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    )}
                  </CardContent>
                </MDBox>
              </MDBox>
            </Card>
            <MDBox sx={{ paddingTop: 1 }}>
              <GeneralInfo assetDetailsData={assetDetails} />
            </MDBox>
          </Grid.Col>
          <Grid.Col span={10}>
            {/* Services section */}
            <AssetServices />
          </Grid.Col>
        </Grid>
        <MDBox
          variant="gradient"
          coloredShadow="info"
          borderRadius="lg"
          boxShadow="xl"
          mt={3}
          p={2}
        >
          <DataTable />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default AssetDetails;
