/* eslint-disable react/prop-types */
import React from "react";

// import { Badge } from "@mantine/core";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

import assetnoimg from "assets/images/assetnoimg.jpg";

// eslint-disable-next-line no-unused-vars
function BaseItem({ image, title, location, type }) {
  const base64Prefix = "data:image/png;base64,";
  return (
    <MDBox width="100%" display="flex" flexDirection="row" alignItems="center" mt={1} mb={1}>
      {image !== "NONE" && (
        <CardMedia
          image={`${base64Prefix}${image}`}
          variant="rectangle"
          sx={{
            width: 100,
            height: 35,
            borderRadius: 0,
            margin: 1,
            padding: 1,
          }}
        />
      )}
      {image === "NONE" && (
        <CardMedia
          image={assetnoimg}
          variant="rectangle"
          sx={{
            width: 100,
            height: 35,
            borderRadius: 0,
            margin: 1,
            padding: 1,
          }}
        />
      )}
      <MDBox width="100%">
        <MDTypography
          style={{
            fontSize: "12px",
            fontWeight: "800",
          }}
        >
          {title}
        </MDTypography>
        <MDTypography variant="body2" fontSize="0.75rem">
          {location}
        </MDTypography>
      </MDBox>

      {/* Add later */}
      {/* <MDBox width="80%" display="flex" alignItems="centre" justifyContent="flex-end" mr={1}>
        {type === "Open Plot" && (
          <Badge color="pink" radius="lg" size="xs" style={{ fontSize: "8px" }}>
            {type}
          </Badge>
        )}
        {type === "Apartment" && (
          <Badge color="grape" radius="lg" size="xs" style={{ fontSize: "8px" }}>
            {type}
          </Badge>
        )}
        {type === "House" && (
          <Badge color="violet" radius="lg" size="xs" style={{ fontSize: "8px" }}>
            {type}
          </Badge>
        )}
      </MDBox> */}
    </MDBox>
  );
}

export default BaseItem;
