import React, { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { fetchAgentLatestProperties } from "services/api.agent.service";
import PropertyItem from "./PropertyItem";

function Base() {
  const [res, setRes] = useState([]);

  useEffect(() => {
    fetchAgentLatestProperties().then((response) => {
      console.log("res: ", response);
      setRes(response);
    });
  }, []);

  const handlePropertyItemClick = (item) => {
    console.log("id: ", item.asset_service);
  };

  return (
    <MDBox border="1px solid" p={1}>
      <MDTypography style={{ fontSize: "14px", fontWeight: "500" }}>
        Assigned Properties
      </MDTypography>
      {res.map((item) => (
        <MDBox border="1px solid red" onClick={() => handlePropertyItemClick(item)}>
          <PropertyItem img={item.asset_primary_image} name={item.asset_name} />
        </MDBox>
      ))}
    </MDBox>
  );
}

export default Base;
