/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Button } from "@mantine/core";

import { IconArrowLeft } from "@tabler/icons";

import AssetInfoCard from "./AssetInfoCard";
import CustomerCard from "./CustomerCard";
import AssetUpdates from "./AssetUpdates";

import AssetDetails from "./AssetDetails";

function Base({ setViewUpdate, data }) {
  const [viewAssetDetails, setViewAssetDetails] = useState(false);
  console.log("data - asset update", data);
  const handleDone = () => {
    setViewUpdate(false);
  };
  return (
    <>
      {!viewAssetDetails && (
        <MDBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <MDBox>
            <MDTypography style={{ fontSize: "24px", fontWeight: "400", color: "#af97b1" }}>
              Asset Update
            </MDTypography>
          </MDBox>
          <MDBox>
            <Button color="indigo" onClick={handleDone} style={{ marginRight: "4px" }}>
              Done
            </Button>
            <Button color="gray" onClick={handleDone} style={{ marginRight: "4px" }}>
              Cancel
            </Button>
          </MDBox>
        </MDBox>
      )}
      {!viewAssetDetails && (
        <>
          <CustomerCard data={data} />
          <AssetInfoCard data={data} setViewAssetDetails={setViewAssetDetails} />
          <AssetUpdates data={data} />
        </>
      )}
      {viewAssetDetails && (
        <MDBox display="flex" flexDirection="row" alignItems="center">
          <MDBox
            width="fit-content"
            height="fit-content"
            borderRadius="xl"
            display="flex"
            style={{ cursor: "pointer" }}
            onClick={() => setViewAssetDetails(false)}
          >
            <IconArrowLeft color="#d27979" />
          </MDBox>
          <MDBox ml={2}>
            <MDTypography style={{ fontSize: "24px", fontWeight: "400", color: "#af97b1" }}>
              Asset Details
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
      {viewAssetDetails && <AssetDetails data={data} />}
    </>
  );
}

export default Base;
