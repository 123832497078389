import React from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import TODO from "./components/Todo";
import Recents from "./components/Recents";
import AssignedProperties from "./components/AssignedProperties";

function Base() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <TODO />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Recents />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <AssignedProperties />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Base;
