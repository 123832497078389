/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";

// import { Modal } from "@mantine/core";

import Grid from "@mui/material/Grid";
// import Avatar from "@mui/material/Avatar";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// eslint-disable-next-line react/prop-types
function FullProfileEditModal({
  handleSave,
  profileData
}) {
  // const [profileStack, setProfileStack] = React.useState([]);
  const [formData, setFormData] = useState(profileData);
  const handleInputChange = (event) => {
    console.log(event.target.name)
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    // Update the local state when profileData prop changes
    setFormData(profileData);
  }, [profileData]);
  const { email, first_name, contact_mobile, pin_code, last_name, address, city, state, country } = formData;
  return (
    <>
      <Grid container px={3}>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox mb={1} pt={2}>
            <MDInput
              type="email"
              value={email}
              placeholder="Email"
              label="Email"
              name="email"
              disabled
              m={4}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pr={1} pt={2}>
                <MDInput
                  label="First Name"
                  placeholder="First Name"
                  value={first_name}
                  onChange={handleInputChange}
                  name="first_name"
                  fullWidth
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pl={1} pt={2}>
                <MDInput
                  label="Last Name"
                  placeholder="Last Name"
                  value={last_name}
                  onChange={handleInputChange}
                  fullWidth
                  name="last_name"
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pr={1} pt={2}>
                <MDInput
                  label="Mobile No."
                  placeholder="Mobile No."
                  name="contact_mobile"
                  value={contact_mobile}
                  onChange={handleInputChange}
                  fullWidth
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container px={3}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1} pr={1} pt={2}>
            <MDInput
              placeholder="Address"
              value={address}
              name="address"
              fullWidth
              onChange={handleInputChange}
              label="Address Line 1"
              autoComplete="off"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pr={1} pt={2}>
                <MDInput
                  label="City"
                  placeholder="City"
                  value={city}
                  onChange={handleInputChange}
                  name="city"
                  fullWidth
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pl={1} pt={2}>
                <MDInput
                  label="State"
                  placeholder="State"
                  value={state}
                  onChange={handleInputChange}
                  fullWidth
                  name="state"
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pr={1} pt={2}>
                <MDInput
                  placeholder="Country"
                  label="Country"
                  value={country}
                  name="country"
                  fullWidth
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1} pl={1} pt={2}>
                <MDInput
                  label="Zip Code"
                  placeholder="Zip Code"
                  value={pin_code}
                  name="pin_code"
                  fullWidth
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        <MDBox pt={2} display="flex" width="100%" justifyContent="center">
          <MDBox mb={2}>
            <MDButton
              variant="contained"
              color="error"
              fullWidth
              size="medium"
              onClick={()=>handleSave(formData)}
            >
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </Grid>
    </>
  );
}

export default FullProfileEditModal;
