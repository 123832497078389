// @mui material components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
// import TimelineItem from "examples/Timeline/TimelineItem";

import { ScrollArea, Skeleton } from "@mantine/core";

import { fetchAssets } from "services/api.service";
import { Button, Card } from "@mui/material";
import AssetItem from "./AssetItem";
// import AssetDetails from "adminLayouts/assetDetails";

function OrdersOverview() {
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleViewAllAssets = () => {
    navigate("/assets");
  };

  const handleAddAsset = () => {
    navigate("/asset");
  }

  useEffect(() => {
    setLoading(true);
    fetchAssets().then((res) => {
      setAssets(res);
      setLoading(false);
    });
  }, []);

  return (
    <Card>
      <MDBox display="flex" alignItems="center" justifyContent="space-between" pt={3} px={3}>
        <MDBox display="flex" alignItems="center">
          <Icon sx={{ color: "red" }} fontSize="inherit">
            home
          </Icon>
          <MDTypography sx={{ color: "red" }} px={2} variant="h6" fontWeight="medium">
            Assets
          </MDTypography>
        </MDBox>
        <Button variant="contained" color="error" size="small" onClick={handleAddAsset}>
          <Icon sx={{ color: "red" }} fontSize="inherit">
            add
          </Icon>
          <p style={{ textTransform:'capitalize', color: "red" }}>Add</p>
        </Button>
      </MDBox>
      {loading &&
        [1, 2, 3, 4, 5, 6, 7].map((item) => (
          <div
            key={item}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Skeleton height={8} circle ml="md" mt="md" />
            <Skeleton height={16} ml="xl" width="50%" mt="md" />
          </div>
        ))}
      {!loading && assets.length > 0 && (
        <>
          <ScrollArea style={{ height: 200 }} scrollbarSize={2}>
            {assets.map((asset) => (
              <Link href={`/assets/${asset.id}`} key={asset.id}>
                {/* <TimelineItem color="success" icon="notifications" title={asset.name} /> */}
                <AssetItem
                  image={asset.primary_image}
                  title={asset.name}
                  location={asset.address?.location_name}
                  type={asset.asset_type}
                />
              </Link>
            ))}
          </ScrollArea>
          <MDBox
            display="flex"
            adlignItems="center"
            justifyContent="center"
            mt={1}
            onClick={handleViewAllAssets}
          >
            <MDTypography
              variant="body2"
              fontSize="0.75rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ color: "red", cursor: "pointer" }}
            >
              view all {`>>`}
            </MDTypography>
          </MDBox>
        </>
      )}
      {!loading && assets.length === 0 && (
        <MDBox height={325} display="flex" alignItems="center" justifyContent="center">
          <MDTypography variant="subtitle2">You have no assets at the moment</MDTypography>
        </MDBox>
      )}
    </Card>
  );
}

export default OrdersOverview;
