/* eslint-disable react/prop-types */
// Mobile auth
// info on email
import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Grid from "@mui/material/Grid";
import { Checkbox } from "@mantine/core";

function Base({ handleSave }) {
  const [value, setValue] = useState([]);

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Grid container sx={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={12} lg={12}>
          <Checkbox.Group value={value} onChange={setValue}>
            <Checkbox
              value="mobile"
              label="Allow messaging on Mobile Number for promotional codes and general information sharing purposes"
            />
            <Checkbox value="email" label="Allow Activity notifications through email" />
          </Checkbox.Group>
        </Grid>
      </Grid>
      <MDBox mt={3} display="flex" justifyContent="center" alignItems="center">
        <MDButton value="SAVE" onClick={handleSave} color="error" style={{ width: "50%" }}>
          SAVE
        </MDButton>
      </MDBox>
    </Grid>
  );
}

export default Base;
