/* eslint-disable react/prop-types */
import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Tabs, Button, Checkbox, Divider } from "@mantine/core";
import {
  IconLiveView,
  IconBrowserPlus,
  IconBackhoe,
  IconFence,
  IconWashPress,
  IconBounceRight,
} from "@tabler/icons";
import { requestToAssiciateServices } from "services/api.service";
import RPSelectItem from "./inactiveItems/RPSelectItem";

// FIXME:
// Incoming Props: inactiveServices, assetId
function Inactive({ inactiveServices, assetId, setReBreathe }) {
  const [recurrenceValue, setRecurrenceValue] = useState("NA");
  const [onPMRequestSuccess, setOnPMRequestSuccess] = useState(false);
  const [onPCRequestSuccess, setOnPCRequestSuccess] = useState(false);
  const [onFencingRequestSuccess, setOnFencingRequestSuccess] = useState(false);
  const [onPlotMaintenanceRequestSuccess, setOnPlotMaintenanceRequestSuccess] = useState(false);
  console.log("Inactive Services: ", inactiveServices);
  const defaultValue = inactiveServices.length > 0 ? inactiveServices[0].serviceName : "";

  // const ins = [
  //   {
  //     service: {
  //       serviceId: 1,
  //       serviceName: "Plot Monitoring",
  //     },
  //   },
  //   {
  //     service: {
  //       serviceId: 2,
  //       serviceName: "Plot Cleaning",
  //     },
  //   },
  //   {
  //     service: {
  //       serviceId: 3,
  //       serviceName: "Fencing",
  //     },
  //   },
  //   {
  //     service: {
  //       serviceId: 4,
  //       serviceName: "Plot Maintenance",
  //     },
  //   },
  // ];

  const handlePlotMonitoringRequestClick = () => {
    console.log("Plot Monitoring Request ");
    const sid = 1;
    requestToAssiciateServices(assetId, sid).then((res) => {
      console.log("Response: ", res);
      if (res) {
        setOnPMRequestSuccess(true);
        setReBreathe(true);
      }
    });
  };

  const handlePlotCleaningRequestClick = () => {
    console.log("Plot Cleaning Request ");
    const sid = 2;
    requestToAssiciateServices(assetId, sid).then((res) => {
      console.log("Response: ", res);
      if (res) {
        setOnPCRequestSuccess(true);
      }
    });
    // TODO: Associate Service to asset with requested satus to True [associateAssetService]
  };

  const handleFencingRequestClick = () => {
    console.log("Fencing Request ");
    const sid = 3;
    requestToAssiciateServices(assetId, sid).then((res) => {
      console.log("Response: ", res);
      if (res) {
        setOnFencingRequestSuccess(true);
      }
    });
  };

  const handlePlotMaintenanceRequestClick = () => {
    console.log("Plot Maintenance Request ");
    const sid = 4;
    requestToAssiciateServices(assetId, sid).then((res) => {
      console.log("Response: ", res);
      if (res) {
        setOnPlotMaintenanceRequestSuccess(true);
      }
    });
  };

  return (
    <MDBox>
      <Tabs defaultValue={defaultValue} orientation="vertical">
        <Tabs.List sx={{ display: "flex", flexDirection: "column" }}>
          {inactiveServices.map((ser) => (
            <Tabs.Tab
              value={ser.serviceName}
              key={ser.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                height: "1rem",
              }}
              // onClick={() =>
              //   handleServiceClickDisplay(ser.service?.serviceName, ser.service?.serviceId, assetId)
              // }
            >
              <MDTypography style={{ fontSize: "12px", fontWeight: "500", color: "2e2e2e" }}>
                {ser.serviceName}
              </MDTypography>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        <Tabs.Panel value="Plot Monitoring" sx={{ marginLeft: "1rem" }}>
          <MDBox>
            <MDBox mb={1} mt={1} ml={1} display="flex" flexDirection="row" alignItems="center">
              <IconLiveView size={24} strokeWidth={2} color="#bf4040" />
              <MDTypography style={{ marginLeft: "4px" }}>Plot Monitoring</MDTypography>
            </MDBox>
            <MDBox p={1}>
              <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#2e2e2e" }}>
                Plot monitoring of an asset refers to the process of tracking and analyzing a
                particular property over time. This can be useful for identifying trends and
                patterns in the property performance, which can help investors make informed
                decisions about buying or selling the asset.
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider size="md" />
          <MDBox display="flex" flexDirection="row" justifyContent="space-between">
            <MDBox>
              <RPSelectItem
                recurrenceValue={recurrenceValue}
                setRecurrenceValue={setRecurrenceValue}
              />
            </MDBox>
            <MDBox display="flex" alignItems="flex-end">
              {!onPMRequestSuccess && (
                <Button
                  leftIcon={<IconBrowserPlus size={14} />}
                  color="red"
                  onClick={handlePlotMonitoringRequestClick}
                >
                  Request Service
                </Button>
              )}
              {onPMRequestSuccess && (
                <Button leftIcon={<IconBounceRight size={14} />} color="green">
                  Request Service
                </Button>
              )}
            </MDBox>
          </MDBox>
        </Tabs.Panel>
        <Tabs.Panel value="Plot Cleaning" sx={{ marginLeft: "1rem" }}>
          <MDBox>
            <MDBox mb={1} mt={1} ml={1} display="flex" flexDirection="row" alignItems="center">
              <IconBackhoe size={24} strokeWidth={2} />
              <MDTypography style={{ marginLeft: "4px" }}>Plot Cleaning</MDTypography>
            </MDBox>
            <MDBox p={1}>
              <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#2e2e2e" }}>
                Plot cleaning is the process of removing unwanted vegetation, debris, and other
                materials from a plot of land. The specific steps involved in plot cleaning will
                vary depending on the size and condition of the plot, as well as the goals of the
                person cleaning it.
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider size="md" />
          <MDBox display="flex" flexDirection="column" justifyContent="space-around" mt={1}>
            <MDBox mt={1}>
              <Checkbox
                label="Remove unwanted vegetation"
                description="One of the key steps in plot cleaning is removing unwanted vegetation. This might involve cutting down overgrown trees and shrubs, pulling up weeds, or using herbicides to kill unwanted plants."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Clear debris and trash"
                description="After removing unwanted vegetation, the next step is to clear any debris and trash that may be present on the plot. This might include picking up loose branches, leaves, and other organic materials, as well as removing any man-made debris such as bottles, cans, or plastic bags."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Dispose of waste properly"
                description="Once all of the debris and trash has been cleared from the plot, it is important to dispose of it properly. This might involve bagging up the waste and taking it to a local landfill or recycling center, or finding another appropriate way to dispose of it."
                color="red"
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center" mt={2}>
            {!onPCRequestSuccess && (
              <Button
                leftIcon={<IconBrowserPlus size={14} />}
                color="red"
                onClick={handlePlotCleaningRequestClick}
              >
                Request Service
              </Button>
            )}
          </MDBox>
        </Tabs.Panel>
        <Tabs.Panel value="Fencing" sx={{ marginLeft: "1rem" }}>
          <MDBox>
            <MDBox mb={1} mt={1} ml={1} display="flex" flexDirection="row" alignItems="center">
              <IconFence size={24} strokeWidth={2} />
              <MDTypography style={{ marginLeft: "4px" }}>Fencing</MDTypography>
            </MDBox>
            <MDBox p={1}>
              <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#2e2e2e" }}>
                Plot fencing is a method of protecting a piece of land by enclosing it with a
                physical barrier, such as a fence.
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider size="md" />
          <MDBox display="flex" flexDirection="column" justifyContent="space-around" mt={1}>
            <MDBox mt={1}>
              <Checkbox
                label="Clear the area where the fence will be installed"
                description=" This may involve removing any existing vegetation or other obstacles that could interfere with the installation of the fence."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Install the posts for the fence."
                description="This will typically involve digging holes for the posts and setting them in place."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Attach the fencing material to the posts"
                description="This will vary depending on the type of fence being installed. For example, if you are installing a wooden fence, you will need to attach the wooden panels to the posts using screws or nails."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Install any gates or other accessories."
                description="This may include installing hinges and handles on gates, or attaching latches or locks to secure the gates."
                color="red"
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center" mt={2}>
            {!onFencingRequestSuccess && (
              <Button
                leftIcon={<IconBrowserPlus size={14} />}
                color="red"
                onClick={handleFencingRequestClick}
              >
                Request Service
              </Button>
            )}
            {onFencingRequestSuccess && (
              <Button leftIcon={<IconBrowserPlus size={14} />} color="green">
                Request Service
              </Button>
            )}
          </MDBox>
        </Tabs.Panel>
        <Tabs.Panel value="Plot Maintenance" sx={{ marginLeft: "1rem" }}>
          <MDBox>
            <MDBox mb={1} mt={1} ml={1} display="flex" flexDirection="row" alignItems="center">
              <IconWashPress size={24} strokeWidth={2} />
              <MDTypography style={{ marginLeft: "4px" }}>Plot Maintenance</MDTypography>
            </MDBox>
            <MDBox p={1}>
              <MDTypography style={{ fontSize: "14px", fontWeight: "300", color: "#2e2e2e" }}>
                Plot maintenance is the process of caring for and managing a piece of land to ensure
                that it remains in good condition. The steps for plot maintenance may vary depending
                on the specific needs of the land and the goals of the landowner.
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider size="md" />
          <MDBox display="flex" flexDirection="column" justifyContent="space-around" mt={1}>
            <MDBox mt={1}>
              <Checkbox
                label="Perform regular maintenance tasks."
                description="This may involve mowing the grass, pruning trees and shrubs, and performing other tasks to keep the land in good condition."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Monitor the condition of the land."
                description="Once all of the debris and trash has been cleared from the plot, it is important to dispose of it properly. This might involve bagging up the waste and taking it to a local landfill or recycling center, or finding another appropriate way to dispose of it."
                color="red"
              />
            </MDBox>
            <MDBox mt={1}>
              <Checkbox
                label="Address any issues or problems that arise."
                description="This may involve removing weeds, repairing damaged fences or gates, or taking other steps to maintain the land."
                color="red"
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center" mt={2}>
            {!onPlotMaintenanceRequestSuccess && (
              <Button
                leftIcon={<IconBrowserPlus size={14} />}
                color="red"
                onClick={handlePlotMaintenanceRequestClick}
              >
                Request Service
              </Button>
            )}
            {onPlotMaintenanceRequestSuccess && (
              <Button leftIcon={<IconBrowserPlus size={14} />} color="orange">
                Requested
              </Button>
            )}
          </MDBox>
        </Tabs.Panel>
      </Tabs>
    </MDBox>
  );
}

export default Inactive;
