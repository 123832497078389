import React from "react";

// @mui material components
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Skeleton } from "antd";

// eslint-disable-next-line react/prop-types
function NotificationItem({ title, description, loading }) {
  return (
    <MDBox position="relative" mb={3}>
      <Skeleton loading={loading} active avatar />
      <Skeleton loading={loading} active avatar paragraph={{ rows: 1 }} />
      <MDBox ml={1} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="80rem">
        <MDTypography style={{ fontSize: "12px", fontWeight: "800" }}>{title}</MDTypography>
        <MDBox mt={0.5}>
          {description ? (
            <MDTypography style={{ fontSize: "12px" }}>{description}</MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default NotificationItem;
