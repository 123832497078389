/* eslint-disable react/prop-types */
import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CardMedia from "@mui/material/CardMedia";

function Base({ img, name }) {
  const base64Prefix = "data:image/png;base64,";
  return (
    <MDBox display="flex" flexDirection="row">
      <CardMedia
        image={`${base64Prefix}${img}`}
        variant="rectangle"
        sx={{
          width: 50,
          height: 35,
          borderRadius: 0,
          margin: 1,
          padding: 1,
        }}
      />
      <MDBox>
        <MDTypography
          style={{
            fontSize: "12px",
            fontWeight: "800",
          }}
        >
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Base;
